//
// Text
//

// Text colors
@each $name, $color in $theme-text-colors {
  .root {
    width: 100vw;
    height: 100%;
    display: grid;
  }

  .help-center-hero {
    width: 100vw;
    display: grid;
    justify-self: center;
    padding: 2rem;
  }

  .help-center-img-card {
    width: 100%;
    height: 40vh;
    display: grid;
    background: url('../../../imgs/src/services-one-shape\ 25x.png')
        center
        center
        no-repeat,
      linear-gradient(99.13deg, #0866ff 43.08%, #f1b807 137.39%);
    background-size: cover;
    border-radius: 5px;
  }

  .help-center-item {
    color: white;
    display: grid;
    justify-content: flex-start;
    place-items: center;
    margin-left: 5%;
  }

  .help-center-faq1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    display: grid;
    color: #f9f9f9;
  }

  .help-center-faq2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    /* identical to box height */
    display: grid;

    color: #3baefe;
  }

  .help-center-faq3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #cccccc;
  }

  .help-center-section-2 {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    background: var(--VoxePay-Ultramarine-Blue-50, #e6efff);
  }

    .help-center-section-2-developer {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
background: var(--Color, #FFF);
  }


  .help-center-section-dev-4 {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    background: var(--VoxePay-Ultramarine-Blue-50, #fff);
  }

  .help-center-section-4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding-bottom: 2rem;
    gap: 2rem;

    div {
      display: flex;
      justify-content: space-between;
      width: 200px;
      height: 56px;
      padding: 1rem;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--VoxePay-Ultramarine-Blue-200, #b4d1ff);
      background: rgba(250, 250, 255, 0.4);
      box-shadow: 0px 4px 28px 2px rgba(255, 255, 255, 0.12);

      span {
        color: var(--VoxePay-Grey-800, #1f2937);
        font-family: 'Poppins';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 16.8px */
      }
    }
  }

  .help-center-section-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 1rem;

    .tc-1 {
      color: var(--VoxePay-Grey-600, #4b5563);
      text-align: center;
      font-family: 'Poppins';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */

      span {
        color: var(--VoxePay-Dark-Pastel-Green-800, #21cb51);
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .tc-2 {
      color: var(--VoxePay-Grey-500, #6b7280);
      text-align: center;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }

  .developer-section-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;

        @media screen and (max-width: 950px) {
      flex-direction: column;
    }


    .developer-section-div-section {
      width: 365px;
      height: 168px;
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid var(--VoxePay-Ultramarine-Blue-100, #cde0ff);
      background: var(--VoxePay-Light-Neutral-White, #fff);

      .developer-section-div-item {
        display: flex;
        justify-content: flex-start;
        padding: 1rem;
        gap: 1rem;

        .rhs-div-con {
          display: flex;
          flex-direction: column;
          text-align: justify;
          gap: 0.5rem;
          .header {
            color: var(--VoxePay-Primary-Ultramarine-Blue, #0866ff);

            /* VoxePay/16px/Semibold */
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          .body {
            color: var(--VoxePay-Grey-600, #4b5563);

            /* VoxePay/14px/Regular */
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
          }
        }
      }
    }
  }

  .help-center-section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .help-center-small-text {
    display: grid;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #0866ff;
  }

  .help-center-bold-text {
    display: grid;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height */

    color: #061b01;
  }

  .help-center-normal-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: normal;
    color: #666666;
    align-items: center;
  }

  .help-center-form {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 603px;
    background: #f9f9f9;
    padding: 2rem;
  }

  .help-center-root {
    display: grid;
    background: #f9f9f9;
    // clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0 86%);
  }

  .help-center-root-form {
    display: grid;
    justify-content: center;
    padding: 2rem;
    background: linear-gradient(0deg, #d2e7cd, #d2e7cd), #979797;
  }

  .help-form {
    // width: 200px;
    // height: 48px;
    // background: #F9F9F9;;
    // padding: 1rem;
    // border-color: 0.1px solid #ccc;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 80%;
    height: 48px;
    padding: 4px;
    padding: 4px;

    background: #f9f9f9;
    border-color: 0.5px solid #fff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .help-center-root-faq {
    display: grid;
    justify-content: start;
    padding: 2rem;
    background: #f9f9f9;
    margin-bottom: 6rem;
  }

  .faq-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #d2e7cd;
    border-radius: 7px 7px 0px 0px;
    width: 98%;
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    white-space: nowrap;
  }

  .help-contact {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    cursor: pointer;
    text-decoration-line: underline;

    color: #0866ff;
  }

  .faq-header .faq-header-text {
    display: grid;
    align-items: center;

    justify-content: start;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #0866ff;
  }

  .faq-header span {
    display: grid;
    align-items: center;
    justify-content: end;
  }

  .faq-body {
    display: grid;
    height: auto;
    border: 1px solid #f3dfdf;
    background: #f9f9f9;
    padding: 1rem;
    width: 98%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */

    color: rgba(19, 18, 18, 0.8);
  }

  .help-center-header-text {
    display: grid;
    font-family: 'Poppins' sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #061b01;
    margin-bottom: 27px;
  }

  .help-center-header-body {
    font-family: 'Poppins' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 43px;
    display: grid;
    color: #666666;
  }

  .help-email {
    font-family: 'Poppins' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: block;
    color: #0866ff;
  }

  .faq_frequently-asked-header {
    font-family: 'Poppins' sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height */
    display: grid;
    margin-top: 149px;

    color: #061b01;
  }

  .faq_frequently-asked-body {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: grid;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #666666;
  }

  .hero-photo-video {
    margin-top: 1.25rem;
  }

  .footer {
    padding-bottom: 2rem;
    width: 100vw;
    background: #e6efff !important;
    display: grid;
    grid-template-columns: 1fr;
    // place-items: center;
    padding: 42px 108px;
    gap: 2rem;
    color: #fff;

    @media screen and (min-width: 992px) {
      // gap: 4rem;

      .social-icons-footer {
        display: grid;
        // margin-top: -4rem;
        width: 20rem;
        grid-template-columns: repeat(5, 1fr);
        // justify-items: center;

        margin-left: -2rem !important;
      }
    }
  }

  .footer-center {
    display: block;
    place-items: center;
    margin-left: 2rem;
  }

  .social-icons-footer {
    display: grid;
    // margin-top: -4rem;
    width: 20rem;
    grid-template-columns: repeat(5, 1fr);
    // justify-items: center;

    margin-left: -2rem !important;
  }

  .social-icon-footer {
    font-size: 1rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }

  .footer .social-icons {
    margin-bottom: 2rem;
  }

  .footer .social-icon {
    color: var(--clr-primary-10);
  }

  .footer p {
    font-size: 1.25rem;
    text-transform: capitalize;
  }

  .footer-quick-link {
    color: #000;

    /* VoxePay/16px/Medium */
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .footer-quick-link-text {
    color: var(--VoxePay-Primary-Ultramarine-Blue, #0866ff) !important;

    /* VoxePay/14px/Medium */
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
  }

  .footer-quick-link-text-2 {
    color: var(--Gray-600, #4b5563) !important;

    text-align: center !important;

    /* VoxePay/14px/Medium */
    font-family: 'Poppins' !important;

    font-size: 14px !important;

    font-style: normal !important;

    font-weight: 500 !important;

    line-height: 24px !important;
    white-space: break-spaces;
  }

  .footer-quick-link-icon {
    display: grid;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 0;
    margin-top: 1rem;
    white-space: nowrap;
    color: #0866ff;
    font-size: 1rem;
  }

  .footer-quick-link-icon span {
    color: #666666;
    justify-content: start;
  }

  .footer-bottom {
    display: grid;
    place-content: center;
    place-items: center;
    // background: url("../../../imgs/src/services-one-shape\ 25x.png") center center no-repeat, #061B01 !important;
    padding-bottom: 2rem;
    border-top: 2px dashed rgba(213, 213, 213, 0.2);
    padding: 2rem;
  }

  .footer-bottom-div {
    color: var(--Gray-700, #374151);

    /* VoxePay/16px/Medium */
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .social-icons {
    display: grid;
    margin-top: 3rem;
    width: 20rem;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
  }

  .footer--center-bottom {
    display: grid;
    grid-template-columns: 1fr;
    background: #deebf4 !important;
    background: #deebf4;
    border-radius: 5px;
  }

  .foot-news-letter {
    // justify-content: center;
    padding: 1rem;
    width: 100%;
    // display: flex;

    @media screen and (min-width: 992px) {
      flex-direction: row;
      // justify-content: flex-end;
    }
  }

  .news-letter-container {
    display: flex;
    background: #deebf4;
    padding: 1rem;
    justify-items: center;
    flex-direction: column;

    @media screen and (max-width: 780px) {
      width: 100%;
    }

    .span-header {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */
      color: #061b01;
    }
  }

  .right-side {
    // padding: 1.5rem;
    display: none;
  }
  .subscribe-policy-body {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */
    margin-top: 0.5rem;

    // display: none;
    color: #061b01;
  }

  .subscribe-policy-body-link {
    color: #0866ff;
    cursor: pointer;
  }

  @media screen and (min-width: 992px) {
    .news-letter-container button {
      display: grid;
      margin-left: 0.75rem;
      //  margin-top: 2rem;
    }

    .help-center-section {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }

    .help-center-section-developer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
    }

    .help-center-section-2 {
      width: 100vw;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      background: var(--VoxePay-Ultramarine-Blue-50, #e6efff);
    }

       .help-center-section-2-developer {
      width: 100vw;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
background: var(--Color, #FFF);    }

    .help-center-hero {
      width: 90vw;
      display: grid;
      justify-self: center;
      padding: 3rem;
    }

    .foot-news-letter {
      display: grid;
      // justify-content: end;
      grid-template-columns: 1fr 1fr;
      margin-right: 7rem;
      padding: 1rem;
    }

    .subcriber-pool {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */

      display: grid;
      color: #061b01;
    }

    .subcriber-pool-body {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      color: #666666;
    }

    .right-side {
      padding: 1.5rem;
      display: grid;
    }

    .subscribe-policy-body {
      // display: grid;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      /* identical to box height */
      margin-top: 0.5rem;
      white-space: nowrap;

      color: #061b01;
    }

    // .news-letter-container button {
    //   display: grid;
    //   margin-left: 3rem;
    // }

    .news-letter-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .help-center-root {
      display: grid;
      background: #f9f9f9;
      clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0 86%);
    }

    .help-center-root {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .faq-body {
      display: grid;
      height: auto;
      border: 1px solid #f3dfdf;
      background: #f9f9f9;
      padding: 1rem;
      width: 90%;
    }

    .faq-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: #d2e7cd;
      border-radius: 7px 7px 0px 0px;
      width: 90%;
      padding: 1rem;
    }

    .help-center-root-form {
      display: grid;
      height: 676px;
      justify-content: center;
      padding: 2rem;
      background: linear-gradient(0deg, #d2e7cd, #d2e7cd), #979797;
    }

    .help-form {
      width: 400px;
      height: 48px;
      background: #f9f9f9;
      border-color: 1px solid #ccc;
    }

    .hero-photo-video {
      margin-top: 6.25rem;
    }

    .footer-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .footer {
      padding-bottom: 2rem;
      background: #e6efff !important;
      width: 100vw;
      // place-items: center;
      padding: 4rem;
    }
  }

  // @media screen and (min-width: 992px) {
  //   .right-side {
  //     display: grid;
  //     padding: 1.5rem;
  //     // display: none;
  //   }
  // }

  .footer-news-body {
    width: 631px;
    display: grid;

    @media screen and (max-width: 780px) {
      width: 100%;
    }
  }

  .flex-small {
    display: flex !important;
    flex-direction: row !important;

    @media screen and (max-width: 780px) {
      display: flex !important;
      flex-direction: column !important;
    }
  }

  .flex-col {
    flex: 0 0 auto;
    width: 66.66666667%;

    @media screen and (max-width: 560px) {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  .flex-subscribe {
    flex: 0 0 auto;
    width: 25%;
    margin-left: 0.75rem !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 780px) {
      flex: 1 0;
      width: 100%;
      margin-top: 1rem !important;
    }
  }
}

.footer-logo {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  span {
    color: var(--Gray-700, #374151);

    /* VoxePay/14px/Semibold */
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
  }
}

.footer-cen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  span {
    color: var(--VoxePay-Grey-800, #1f2937);

    /* VoxePay/13px/Medium */
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 184.615% */
  }
}

.help-center-section-patners {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4rem;
  background: var(--VoxePay-Ultramarine-Blue-50, #ffffff);
}
