.taxoldPayments {



  .taxPaymentRadioButtons {
    background: #FFFFFF;
    width: 480px;
    display: grid;
    margin: auto;
    padding: 40px;

    .tpText {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #061B01;
      padding-bottom: 40px;
    }

    label {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      padding-bottom: 20px;

      input[type="radio"]:checked {
        border-color: green;
      }
    }

    .tpProceedButton {
      padding-top: 30px;
      text-align: center;

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #FFFFFF;
        border-radius: 4px;
        padding: 12px 34px;
        border: none;
      }
    }
  }
}

.keyInTaxPayments {
  padding: 30px;

  .taxPaymentsBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  > :last-child {
    margin: auto;
    background: #FFFFFF;
    padding: 40px;
    width: 480px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #061B01;
    }

    > :nth-child(2) {
      padding-block: 40px;

      label {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }

    > :last-child {
      text-align: center;

      button {
        background:  #0866FF;
        border-radius: 4px;
        border: none;
        padding: 12px 34px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}

.bulkTaxUpload {
  padding: 30px;

  .bulkTaxUploadBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  > :last-child {
    margin: auto;
    text-align: center;
    background: #FFFFFF;
    padding: 40px;
    width: 580px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #061B01;
      padding-bottom: 30px;
    }

    .btUploadButtons {
      padding-bottom: 25px;

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #0866FF;
        border: 1px solid #0866FF;
        padding: 15px 75px;
        border-radius: 4px;
        background: #FFFFFF;
        width: 100%;
      }
    }
  }
}





.taxPayments {
  padding: 30px;
  overflow-y: auto;

  .taxPaymentsBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  .taxPaymentsRow1 {
    padding: 40px;
    border-radius: 5px;
    background: var(--neutral-color-1, #FFF);
    margin: auto;
    width: 580px;

    .makeTaxPaymentText {
      color: var(--Header-Text, #061B01);
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .chooseTaxTypeText {
      color: var(--Paragraph-Text-Color, #666);
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-block: 40px 10px;
    }

    .taxPaymentType {
      display: flex;
      gap: 20px;

      > div {
        label {
          width: 240px;
          height: 156px;
          border-radius: 5px;
          background: var(--neutral-color-1, #FFF);
          padding-inline: 16px;
          cursor: pointer;

          .inputDiv {
            padding-block: 10px;
            text-align: right;
          }
        }

        .taxType {
          padding-top: 10px;
          color: var(--paragraph-text-color, #666);
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .taxProceedButton {
      padding-top: 40px;
      text-align: center;

      button {
        color: #FFF;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 4px;
        background: var(--primary-color, #0866FF);
        padding: 12px 48px;
        border: none;
      }
    }
  }

  .singleKeyIn {
    background: var(--Neutral-Color1, #FFF);
    padding: 40px;
    max-width: 900px;
    margin: auto;
    overflow-y: auto;

    .singleKeyInRow1 {
      display: flex;

      > :first-child {
        flex-basis: 5%;
      }

      > :nth-child(2) {
        color: var(--Header-Text, #061B01);
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        flex-basis: 95%;
      }
    }

    .singleKeyInForm {
      padding-block: 40px;
      //width: 100%;

      .selectFundingDiv {
        > :first-child {
          color: #061B01;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-bottom: 10px;
        }

        > :nth-child(2) {
          display: flex;
          width: 100%;
          gap: 20px;

          > div {
            width: 50%;
          }
        }
      }

      .beneficiaryDetails {
        width: 100%;

        > :first-child {
          color: #666666;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-top: 20px;
        }

        .detailsRow {
          display: flex;
          width: 100%;
          gap: 20px;

          > div {
            width: 50%;
          }
        }
      }
    }

    .singleKeyInSubmitButton {
      text-align: center;

      button {
        color: #FFF;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 4px;
        background: #0866FF;
        padding: 12px 48px;
        border: none;
        cursor: pointer;
      }
    }
  }

  .pensionPaymentTable {
    .pensionPaymentTableRow1 {
      display: flex;
      gap: 40px;
      justify-content: right;
      padding-block: 40px;

      > :first-child {
        button {
          color: var(--Primary-Color, #0866FF);
          text-align: center;
          font-family: Poppins, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 4px;
          border: 1px solid var(--Primary-Color, #0866FF);
          padding: 12px 34px;
          cursor: pointer;
        }
      }

      > :nth-child(2) {
        button {
          color: #FFF;
          text-align: center;
          font-family: Poppins, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 4px;
          background: var(--Primary-Color, #0866FF);
          padding: 12px 34px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}

.ppSuccessModal {
  display: grid;
  gap: 40px;
  padding-inline: 30px;
  justify-content: center;

  .ppSuccessModalTitle {
    color: var(--Header-Text, #061B01);
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .ppSuccessModalDesc {
    color: var(--Paragraph-Text-Color, #666);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ppSuccessModalActionButton {
    margin: auto;

    button {
      color: #FFFFFF;
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 4px;
      background: var(--Primary-Color, #0866FF);
      padding: 12px 48px;
      border: none;
      cursor: pointer;
    }
  }
}

th.ant-table-cell {
  background: #F2F2F2 !important;
}

