.mdaPayable {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .mdaPayableRow1 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 30px 12px;

    .mdaPayableBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #666666;
      }

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #061B01;
      }
    }

    > :last-child {
      display: flex;
      align-items: center;
    }
  }

  .mdaPayableRow2 {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 25px;
    padding-block: 18px 40px;

    > :first-child {
      border-radius: 4px;
      border: 1px solid #A5CE9B;
      background: #FFFFFF;
      box-shadow: 0 4px 12px 0 rgba(31, 133, 5, 0.08);
      padding-block: 9px 17px;
      padding-inline: 18px;

      > :first-child {
        color: #061B01;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
      }

      > :nth-child(2) {
        color: #061B01;
        font-family: Poppins, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-block: 13px 11px;
        text-align: right;
      }

      > :nth-child(4) {
        display: flex;
        gap: 9px;
        padding-top: 34px;
        border-bottom: 1px solid #F4F4F4;

        > :first-child {
          color: #0866FF;
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        > :nth-child(2) {
          color: #666;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      > :last-child {
        color: #666666;
        font-family: Poppins, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 5px;
      }
    }

    > :nth-child(2) {
      border-radius: 4px;
      border: 1px solid #FBD0D1;
      background: #FFFFFF;
      box-shadow: 0 4px 12px 0 rgba(238, 21, 29, 0.08);
      padding-block: 9px 17px;
      padding-inline: 18px;

      > :first-child {
        color: #061B01;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
      }

      > :nth-child(2) {
        color: #061B01;
        font-family: Poppins, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-block: 13px 11px;
        text-align: right;
      }

      > :nth-child(4) {
        display: flex;
        gap: 9px;
        padding-top: 34px;
        border-bottom: 1px solid #F4F4F4;

        > :first-child {
          color: #EE151D;
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        > :nth-child(2) {
          color: #666;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      > :last-child {
        color: #666;
        font-family: Poppins, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 5px;
      }
    }

    > :last-child {
      display: grid;
      gap: 18px;

      > div {
        border-radius: 5px;
        background: #FFFFFF;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
        padding-block: 10px 30px;
        padding-inline: 25px;

        > :first-child {
          color: #666666;
          text-align: center;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-bottom: 12px;
        }

        > :nth-child(2) {
          display: grid;
          grid-template-columns: auto auto;
          gap: 16px;

          > :first-child {
            display: flex;
            justify-content: space-between;
            gap: 70px;

            > :last-child {
              border-right: 1px solid #CCCCCC;
              padding-right: 16px;
              text-align: right;

              > :first-child {
                color: #000000;
                font-family: Poppins, sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              > :nth-child(2) {
                color: #666666;
                font-family: Poppins, sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }

          > :last-child {
            display: flex;
            justify-content: space-between;
            gap: 60px;

            > :last-child {
              text-align: right;

              > :first-child {
                color: #000000;
                font-family: Poppins, sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              > :nth-child(2) {
                color: #666666;
                font-family: Poppins, sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }

  .mdaPayableRow3 {
    color: #061B01;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .mdaPayableTabs {
    background: #FFFFFF;
    padding-inline: 20px;
    padding-block: 16px 32px;
    border-radius: 5px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

    .ant-tabs-tab-btn {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: normal;
      color: #061B01 !important;
    }

    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: normal;
      color: #061B01 !important;
    }

    .ant-tabs .ant-tabs-ink-bar {
      background: #3BAEFE !important;
    }
  }

  .mdaPayableRow4 {
    > :nth-child(2) {
      color: #061B01;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .mdaPayableSelectOptions {
      display: flex;
      align-items: center;
      gap: 15px;
      flex-wrap: wrap;
      padding-block: 12px 28px;

      .ant-select-single .ant-select-selector {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
      }

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        background: #3BAEFE;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        padding: 8px;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}