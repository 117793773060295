@use 'core/assets/sass/components/variables' as *;

.preview-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0 20px;

    .preview-header {
        background: $white;
        padding: 20px 0;
        margin-bottom: 10px;
    }
}

.card {
    border-radius: 5px !important;
    background: #FFF !important;
    border-color: $white !important;
}

.project-preview-card {
    width: 100% !important;
    padding-top: 30px;
    padding-bottom: 20px;
}

.preview-btn-container {
    display: flex;
    justify-content: end;
    margin: 10px auto 20px auto;
    width: 100%;

}