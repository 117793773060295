.businessSetup{

  .profileInformation {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    padding-block: 10px 20px;
  }

  .businessDetails {
    display: flex;
    width: 100%;
    gap: 20px;

    > div {
      width: 50%;

      select option {
        background-color: #f0f0f0;
        color: #061b01 !important;
        opacity: 1;
          }
    }
  }

  .proofOfAddress {
    display: flex;
    align-items: center;
    gap: 16px;

    :nth-child(2) {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #000;
    }
  }

  .businessDetailsButton {
    display: flex;
    gap: 20px;
    padding-top: 40px;

    .businessDetailsSkipButton {
      button {
        border-radius: 4px;
        border: 1px solid #0866FF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        color: #0866FF;
        text-align: center;
        background: #FFF;
        padding: 10px 48px;
        cursor: pointer;
        width: 400px;
      }
    }

    .businessDetailsNextButton {
      button, Button {
        color: #FFF;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        border-radius: 4px;
        background: #0866FF;
        padding: 10px 48px;
        border: none;
        cursor: pointer;
        width: 400px;
      }
    }
  }


  .registrationType {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    color: #20608C;
  }

  .documentText {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
  }

  .businessRegNextButton {
    padding-top: 40px;
    text-align: center;

    button {
      color: #FFF;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      border-radius: 4px;
      background: #0866FF;
      padding: 10px 48px;
      border: none;
      cursor: pointer;
      width: 400px;
    }
  }

  .addBusinessRep {
    padding-top: 40px;
    margin: auto;
    
    button {
      border-radius: 4px;
      border: 1px solid #0866FF;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      color: #0866FF;
      text-align: center;
      background: #FFF;
      padding: 10px 48px;
      cursor: pointer;
      width: 400px;
    }
  }


  .businessProfileNextButton {
    padding-top: 40px;
    
    button {
      color: #FFF;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      border-radius: 4px;
      background: #0866FF;
      padding: 10px 48px;
      border: none;
      cursor: pointer;
      width: 400px;
    }
  }






  .profileInformationDiv {
    // > div {
    //   display: flex;
    //   gap: 20px;
    // }
  }
}

