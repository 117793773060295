//
// Text
//

// Text colors
@each $name, $color in $theme-text-colors {
  // Base color

  .hero .underline {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  .hero-img {
    display: none;
  }

  .hero-img-2 {
    display: none;
  }

  .balance-card-root {
    display: none;
  }

  .hero {
    // background: #fff;
    margin-top: 4rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 885px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

    .hero-2 {
    // background: #fff;
    margin-top: 4rem;
    // margin-bottom: 3rem;

    @media screen and (max-width: 885px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .hero-center {
    // min-height: calc(100vh - 20rem);
    display: grid;
    place-items: center;
    text-align: center;
  }

  .hero-info span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    display: block;
    margin-bottom: 6;

    /* identical to box height */

    color: #c9a22d;
  }

  .hero-info .hero-text {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #666666;
  }

  .hero-icons {
    justify-items: start;
  }

  .hero-btn {
    margin-top: 1.25rem;
  }

  .quick-links {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    justify-content: center;
    justify-items: center;
  }

  .quick-links span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    /* identical to box height, or 267% */

    white-space: nowrap;

    color: #666666;
  }

  .img-div {
    align-items: center;
    padding: 20px 30px;
    gap: 5px;
    width: 228px;
    height: 136px;
    background: #deebf4;
    border-radius: 10px;
    margin-top: 1rem;
    box-shadow: var(--light-shadow);
    cursor: pointer;
  }

  .img-div:hover {
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: #f4db89;
  }

  .img-items {
    margin: 0 auto;
  }

  .quick-links div img {
    max-width: 3.6875rem;
    max-height: 3.6875rem;
    object-fit: cover;
  }

  .getStarted-root {
    width: 100% !important;
    text-align: center;
    @media screen and (min-width: 992px) {
      width: 567px !important;
      text-align: left;
    }
  }

  .getStarted-root span {
    text-align: center !important;
    font-size: 1.5rem !important;
    line-height: 2.5rem !important;
    color: #000000 !important;
    font-weight: 700 !important;
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;

    @media screen and (min-width: 992px) {
      text-align: left !important;
      font-size: 1.8rem !important;
      line-height: 2.813rem !important;
    }
  }

  .d-flex-alt {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 580px) {
      display: flex;
      flex-direction: column;
    }
  }

  .btn-getstarted {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }

  .btn-getstarted button {
    justify-content: center;
    align-items: center;
    padding: 12px 34px;
    gap: 10px;
    width: 206px;
    height: 51px;
    background: #0866FF;
    border-radius: 4px;
    border-color: transparent;
  }

  .btn-getstarted .btn-getstarted-text {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff !important;
  }

  .getStarted-text span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #666666;
  }

  @media screen and (min-width: 580px) {
    .quick-links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2rem;
      justify-content: center;
      justify-items: center;
    }
  }

  @media screen and (min-width: 992px) {
    // .hero-img-root {
    //   // margin-top: 20px;
    // }

    .hero-img {
      display: block;
      position: relative;
      // top: calc(100vh * 1.2);
      // left: calc(100vh * 0.9);
      // width: calc(100vh * 0.5);
      // height: calc(100vh * 0.6);
    }

    // .hero-img-2 {
    //   display: block;
    //   position: absolute;
    //   top: calc(100vh * 1);
    //   left: calc(100vh * 0.6);
    //   width: calc(100vh * 0.52);
    //   height: calc(100vh * 0.51);
    // }

    // .balance-card-root {
    //   display: block;
    //   position: absolute;
    //   top: calc(100vh * 1);
    //   left: calc(100vh * 0.6);
    //   width: calc(100vh * 0.52);
    //   height: calc(100vh * 0.51);
    // }

    // .balance-card {
    //   position: absolute;
    //   width: 367px;
    //   height: 102px;
    //   left: 155px;
    //   top: 73px;
    //   background: #FFFFFF;
    //   box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    //   border-radius: 5px;
    // }

    // .balance-card-content {
    //   display: grid;
    //   grid-template-columns: 1fr 1fr;
    // }

    // .balance-card-icon {
    //   justify-self: start;
    //   padding: 2.7rem;

    // }

    // .balance-card-number {
    //   font-family: 'Poppins' sans-serif;
    //   font-style: normal;
    //   font-weight: 600;
    //   font-size: 20px;
    //   line-height: 30px;
    //   /* identical to box height */

    //   color: #061B01;
    // }

    // .balance-card-current {
    //   justify-self: end;
    //   padding: 1rem;

    // }

    // .balance-card-hide {
    //   font-family: 'Poppins' sans-serif;
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 18px;
    //   color: #666666;
    //   display: grid;
    //   grid-template-columns: 1fr 1fr;

    // }

    // .balance {
    //   font-family: 'Poppins' sans-serif;
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 18px;
    //   /* identical to box height */
    //   color: #666666;
    //   display: grid;
    //   grid-template-columns: 1fr 1fr;
    //   white-space: nowrap;

    // }

    .hero-center {
      grid-template-columns: 1fr;
    }

    .hero-photo {
      width: 34.313rem !important;
      height: 23.375rem !important;
      object-fit: cover;
      border-radius: var(--radius);
      position: relative;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    // .hero-img::before {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   border: 0.25rem solid var(--clr-primary-5);
    //   top: 2rem;
    //   right: -2rem;
    //   border-radius: var(--radius);
    // }
    .about-img {
      width: 34.75rem;
      height: 34.125rem;
      object-fit: cover;
      border-radius: var(--radius);
      position: relative;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .quick-links {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 2rem;
      justify-content: center;
      justify-items: center;
    }
  }

  // .hero-photo {
  //   max-width: 35.3125rem;
  //   max-height: 33.6875rem;

  //   object-fit: cover;
  //   border-radius: var(--radius);
  //   position: relative;
  // }
}

.arrow-style {
  width: 1.125rem !important;
  height: 1.125rem !important;
}

.welcome-body-root {
  width: 567px !important;
  text-align: left;
  margin: 2rem;

  @media screen and (max-width: 580px) {
    width: 100% !important;
    margin: 0.5rem;
  }

  }




  .bg-img{

}


.hero-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 95vw;
  height: 50vh;
  padding-top: 2rem;
  gap: 1.5rem;

      @media screen and (max-width: 580px) {
  margin-top: 6rem;

    }

    button{
      display: flex;
width: 200px;
height: 52px;
padding: 0px 20px;
justify-content: center;
align-items: center;
gap: 12px;
border-radius: 4px;
background: var(--VoxePay-Primary-Ultramarine-Blue, #0866FF);
border: none;

color: var(--VoxePay-Light-Neutral-White, var(--Color, #FFF));

/* VoxePay/14px/Semibold */
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
    }
}

.hero-header{
  color: var(--VoxePay-Grey-800, #1F2937);
text-align: center;

/* Voxepay/H4px */
font-family: 'Poppins';
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 48px; /* 120% */
}

.hero-header-dev{
  color: var(--VoxePay-Grey-800, #1F2937);
text-align: center;

/* Voxepay/H4px */
font-family: 'Poppins';
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 48px; /* 120% */
width: 700px;

    @media screen and (max-width: 780px) {
width: 100%;
    }
}

.hero-header-2{
color: var(--VoxePay-Grey-800, #1F2937);

/* Voxepay/H5 32px */
font-family: 'Poppins';
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 40px; /* 125% */
}

.hero-text{
  color: var(--VoxePay-Grey-800, #1F2937);
text-align: center;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 25.2px */
width: 40%;


  @media screen and (max-width: 580px) {
width: 95%;
  }
}

.hero-text-2{
color: var(--VoxePay-Primary-Ultramarine-Blue, #0866FF);
text-align: center;
font-family: 'Poppins';
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 36px */


  @media screen and (max-width: 580px) {
width: 95%;
  }
}

.img-hero-img-dev{
  display: flex;
  width: 91vw;
  // height: 100%;
 

    @media screen and (max-width: 780px) {
//  margin-top: 2rem;  
}
}

.img-hero{
  display: flex;
  width: 98vw;
  height: 100%;
 

    @media screen and (max-width: 780px) {
//  margin-top: 2rem; 
 }
}

.img-hero-dev{
  display: flex;
  justify-content: space-between;
  width: 80vw;
  height: 100%;
 

    @media screen and (max-width: 780px) {
      flex-direction: column;
 }

}



.help-center-rw{
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  width: 70%;
  height: 100%;
  gap: 1rem;


  .help-text-rw-1{
    color: var(--VoxePay-Grey-800, #1F2937);

/* Voxepay/H5 32px */
font-family: 'Poppins';
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 40px; /* 125% */
  }

    .help-text-rw-2{
      ul{
        list-style-type: disc !important;
      }
color: var(--VoxePay-Grey-800, #1F2937);

/* VoxePay/18px/Medium */
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 177.778% */
    }

      button{
display: flex;
width: 200px;
height: 52px;
padding: 0px 20px;
justify-content: start;
align-items: center;
gap: 12px;
border-radius: 4px;
background: var(--VoxePay-Primary-Ultramarine-Blue, #0866FF);
border: none;
margin-top: 1.4rem;


color: var(--VoxePay-Light-Neutral-White, var(--Color, #FFF));

/* VoxePay/14px/Semibold */
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
    }

}

.img-div-rw{
      width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.hero-input{
  display: flex;
  justify-content: space-between;
  align-items: center;
width: 35%;
height: 56px;
padding: 0px 8px;
align-items: center;
border-radius: 8px;
border: 1px solid var(--VoxePay-Ultramarine-Blue-400, #83B2FF);
background: var(--VoxePay-Light-Neutral-Ghost-White, #FAFAFF);

/* Webapp/Fields Shadow 1 */
box-shadow: 0px 2px 24px 4px rgba(192, 197, 212, 0.24);

   @media screen and (max-width: 780px) {
width: 70%;
 }



  .custom-input {
    width: 100%;
border: none;
margin-left: 0.5rem;
    padding: 10px;
    font-size: 16px;
    border: none; /* Remove the default border */
    outline: none; /* Remove the default outline */
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.custom-input:focus {
    border: none; /* Ensure the border remains hidden */
    outline: none; /* Ensure the outline remains hidden */
    box-shadow: none; /* Remove the shadow on focus */
}

.custom-input::placeholder {
  color: var(--VoxePay-Ultramarine-Blue-500, #6AA3FF);
font-family: 'Poppins';
font-size: 14px;
font-style: italic;
font-weight: 500;
line-height: 24px; /* 171.429% */
    opacity: 1; /* Ensures the color is fully opaque */
}
}



.help-center-rw-personal{
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 70%;
  height: 100%;
  gap: 3rem;


  .help-text-rw-1{
color: var(--VoxePay-Grey-800, #1F2937);
text-align: center;

/* Voxepay/H6 24px */
font-family: 'Poppins';
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 133.333% */
  }

  .pateners{
    display: flex;
width: 70%;
height: 100%;
padding: 31px 33px 14px 33px;
flex-direction: column;
// justify-content: flex-end;
// align-items: center;
gap: 31px;
padding-top: 2rem;
border-radius: 12px;
border: 1px solid var(--VoxePay-Ultramarine-Blue-50, #E6EFFF);
background: #FFF;

  @media (max-width: 768px) {
    
    width: 75vw;
    padding-top: 0.5rem;

  }
    

div{
     display: grid;
    grid-template-columns: repeat(6, 1fr); 
    padding: 0.5rem;

  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    
        grid-template-columns: repeat(2, 1fr); /* Two items per row */
        // padding: 0.5rem;
        // gap: 15px;
  }
    
}
  }



      button{
display: flex;
width: 200px;
height: 52px;
padding: 0px 20px;
justify-content: center;
align-items: center;
gap: 12px;
border-radius: 4px;
background: var(--VoxePay-Primary-Ultramarine-Blue, #0866FF);
border: none;
margin-top: 1.4rem;


color: var(--VoxePay-Light-Neutral-White, var(--Color, #FFF));

/* VoxePay/14px/Semibold */
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
    }

}