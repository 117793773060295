.alt-bg {
  background: #f5f5f5;
  height: 100%;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.input-height {
  min-height: 1.425rem;
}

input {
  &::placeholder {
    &::after {
      content: "*";
      position: relative;
      font-size: inherit;
      color: $danger;
      padding-left: 0.25rem;
      font-weight: bold;
    }
  }
}

.responsive-card {
  width: 479px !important;
  overflow-x: auto;
  border: 0 !important;

  @media only screen and (max-width: 568px) {
    width: 90% !important;
  }
}
.invoice-card {
  padding: 1.75rem !important;
  @media only screen and (max-width: 568px) {
    padding: 1.25rem !important;
    padding-bottom: 0.75rem !important;
  }
}

.invoice-card-no-top {
  padding: 1.75rem !important;
  padding-top: unset !important;
  @media only screen and (max-width: 568px) {
    padding: 1.25rem !important;
    padding-top: unset !important;
    padding-bottom: 0.75rem !important;
  }
}


.responsive-card-large {
  width: 944px !important;

  // overflow-x: auto;
  @media only screen and (max-width: 885px) {
    width: 90% !important;
  }
}

.modal-card {
  width: 479px !important;
  overflow-x: auto;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.modal-card-service {
  width: 800px !important;
  overflow-x: auto;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.modal-card-success {
  width: 479px !important;
  padding: 2rem;
  overflow-x: auto;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
}
.modal-card-success-lg {
  width: 500px !important;
  padding: 2rem;
  overflow-x: auto;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.modal-card-signup {
  width: 411px !important;
  border-radius: 0px;
  overflow-x: auto;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.modal-card-user {
  width: 480px !important;

  background: #ffffff;
  overflow-x: auto;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.touched-step {
  padding-left: 0.885rem;
  padding-right: 0.885rem;
  padding-top: 0.569rem;
  padding-bottom: 0.569rem;
  background: #6366F1;
  border: 0.25rem solid #3BAEFE;
  color: $white;
  border-radius: 50%;
  min-width: 2.75rem;
}

.unTouched-step {
  padding-left: 0.885rem;
  padding-right: 0.885rem;
  padding-top: 0.569rem;
  padding-bottom: 0.569rem;
  background: #ffffff;
  border: 0.25rem solid #6366F1;
  color: $dark;
  border-radius: 50%;
  min-width: 2.75rem;
}

.overflow-none {
  overflow-x: unset !important;
}

.touched-line {
  width: 10rem;
  height: 0px;
  border: 0.125rem solid #6366F1;
  border-radius: 0.25rem;
}

.unTouched-line {
  width: 10rem;
  height: 0px;
  border-radius: 0.25rem;
  border: 0.125rem solid $white;
}

.overflow-y {
  overflow-y: scroll;
}

div::-webkit-scrollbar {
  width: 6px;
  /* set the width of the scrollbar */
}

div::-webkit-scrollbar-track {
  background: $white;
  /* set the background color of the scrollbar track */
}

div::-webkit-scrollbar-thumb {
  background-color: $scroll;
  /* set the color of the scrollbar thumb */
  border-radius: 0.25em;
  /* round the edges of the scrollbar thumb */
}

.list-style {
  list-style: disc;
}

.list-style li {
  color: $scroll;
}

.btn-pay {
  bottom: 1.5rem;
  right: 1.625em;
  position: absolute;

  @media only screen and (max-width: 885px) {
    left: 50%;
    transform: translateX(-50%);
    right: unset;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: unset;
    margin-top: 1.5rem;
  }
}

.rule {
  width: 419px;

  @media only screen and (max-width: 885px) {
    width: 100%;
  }
}

.home-btn {
  width: 12.875rem !important;
  height: 3.188rem !important;
}
.userHome-btn {
  width: 11.25rem !important;
  height: 3.188rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-modal {
  width: 536px;
  height: 326px;

  @media only screen and (max-width: 768px) {
    width: 95vw;
    height: 20.375rem;
  }
}

.negative-left {
  margin-left: -0.094rem;
}
.negative-top {
  margin-top: -0.094rem;
}

.text-wrap {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.fluid-width {
  width: 12.5rem !important;

  @media only screen and (max-width: 580px) {
    width: 4.5rem !important;
  }
}

.mda-ref {
  width: 400px;
  height: 48px;
  @media only screen and (max-width: 885px) {
    width: 80%;
    height: 3rem;
  }
}

.drop {
  position: absolute;
  min-height: 100px;
  max-height: 177px;
  background: $white;
  border: 1px solid #666666 !important;
  border-radius: 10px;
  width: 400px;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    width: 84%;
    //height: 11.063rem;
  }
}
.mda-border {
  border-bottom: 1px solid #e3e3e3 !important;

  :hover {
    background-color: $scroll;
  }
}

.mda-search-container {
  display: flex;
  flex-direction: row;

  align-items: center;
  @media only screen and (max-width: 885px) {
    flex-direction: column;
    justify-content: center;
  }
}

.mda-search {
  width: 263px !important;
  height: 48px !important;
  margin-right: 0.875rem !important;
  @media only screen and (max-width: 885px) {
    width: 100% !important;
    height: 3rem !important;
    margin-right: unset !important;
  }
}

@media screen and (max-width: 885px) {
  .btn-gap {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
}

.sev-cen {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sev-pr {
  color: var(--header-text, #061b01);
  font-size: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.custom-modal-width {
  max-width: 100%; /* Adjust this value as per your need */
  width: 1000px !important; /* Set a fixed width if desired */
display: flex;
padding: 60px 40px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 40px;

}

.kyc{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.kyc-header{
color: var(--VoxePay-Dark-Neutral-Dark-Navy-Blue, #050649);
text-align: center;

/* Voxepay/H6 24px */
font-family: 'Poppins';
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 133.333% */


.kyc-body{
  color: var(--VoxePay-Dark-Shade-700, #030507);

/* VoxePay/14px/Regular */
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
}


  
}


.kyc-body-header{
  color: var(--VoxePay-Secondary-Picton-Blue, #3BAEFE);

/* Voxepay/H6 24px */
font-family: "Poppins";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 133.333% */
}

.kyc-btn{
  display: flex;

  width: 100%;


border-radius: 4px;
background: var(--VoxePay-Primary-Ultramarine-Blue, #0866FF);

color: var(--VoxePay-Ultramarine-Blue-50, #E6EFFF);

/* VoxePay/18px/Medium */
font-family: "Poppins";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 177.778% */
}

.kyc-ul{
  ul{
          list-style-type: disc; /* Ensures the dots appear */
      margin-left: 20px; /* Add space if needed */

      li{
        color: var(--VoxePay-Dark-Shade-700, #030507);

/* VoxePay/16px/Medium */
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
gap: 1rem;
      }

  }
}