@import "admin/sidebar";
@import "admin/header";
@import "admin/setupAdministration/index";
@import "admin/payments/index";
@import "admin/collections";
@import "admin/oagf-overview";
@import "admin/mda-balance-sheet";
@import "admin/mda-directory";
@import "admin/mda-details";
@import "admin/analytics/index";

.mainDashboard {
  width: 100vw;
  height: 100vh;
  display: grid;

  header {
    background: #ffffff;
    height: 93px;
    overflow: hidden;
  }

  .dashboardRow2 {
    display: grid;
    grid-template-columns: 276px auto;
    height: calc(100vh - 93px);
    background: #f9f9f9;

    @media (max-width: 885px) {
      display: block;
    }

    .dashboardSidebar {
      background: #f2f2f2;
    }

    main {
      background: #f9f9f9;
      padding: 35px 32px;
      // height: calc(100vh - 93px);

      @media screen and (max-width: 885px) {
        padding: 1rem;
      }
    }

    @media (max-width: 600px) {
      grid-template-columns: 100%;
    }
  }

  .dashboard-main-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5rem;
    background: #f5f5f5;
    padding: 2rem;
    padding: 1rem;
    align-items: center;
  }

  .setup-administration {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #666666;
  }

  .dashboard-main-header div {
    margin: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #666666;
  }

  .dashboard-main-header div div {
    margin: 0.5rem;
    background: #0866FF;
    transform: rotate(-90deg);
  }

  .dashboard-main-header div div div {
    margin: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #08140c;
  }

  .dashboard-header-img {
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
    height: 200px;
    background: url("../../imgs/dashboard/Cover\ Photo.svg") center center
        no-repeat,
      linear-gradient(99.13deg, #0866FF 43.08%, #f1b807 137.39%);
    background-size: cover;
  }

  .dashboard-root {
    background: #f5f5f5;
    height: calc(100vh - 93px);
    overflow-y: auto;
  }

  .dashboard-root-2 {
    // background: #f5f5f5;
    height: calc(100vh - 93px);
    overflow-y: auto;
  }

  .main-inner {
    display: grid;
    position: relative;
    top: -7rem;
    z-index: 2;
    grid-template-columns: 25% 75%;
    gap: 1.5rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }

  .dahboard-main-side {
    display: grid;
    width: 100%;
    height: 100%;

    background: #ffffff;
    border: 1.5px solid #e5e5e5;
    border-radius: 4px;
    box-shadow: var(--light-shadow);
  }

  .dashboard-main {
    width: 100%;
    //height: 100%;

    background: #ffffff;
    border: 1.5px solid #e5e5e5;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: var(--light-shadow);
  }

  .dashboard-main-headers {
    display: flex;
    flex-direction: row;
    padding: 3rem;
    gap: 2.625rem;
    border-bottom: 1px solid #e5e5e5;
    height: 2rem;
    padding-left: 1rem;

    @media screen and (max-width: 600px) {
      gap: 1.5rem;
      padding-right: 1rem;
    }
  }

  .dasboard-header-text-active {
    display: grid;
    align-items: end;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
    color: #061b01;
    height: 3.125rem;
  }

  .dasboard-header-text-active::after {
    content: "";
    width: 100%;
    height: 4px;
    background-color: var(--clr-primary-5);
    margin: 1.6rem auto;
    display: grid;
    transition: width 0.3s linear;
  }

  .corporate-profile {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #08140c;
  }

  .dasboard-header-text-active:hover::after {
    width: 100%;
    transition: width 0.3s linear;
  }

  .dasboard-header-text {
    display: grid;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #666666;
  }

  .dahboard-main-side-content {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    // justify-content: center;
  }

  .dahboard-main-side-content-first {
    display: grid;
    grid-template-rows: 1fr 1fr;
    border-bottom: 1.5px solid #e5e5e5;
  }

  .side-img-box {
    display: grid;
    justify-content: center;
    margin-top: 2rem;
  }

  .side-text-container {
    display: grid;
    justify-content: center;
    margin-top: 1rem;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #061b01;
  }

  .dashboard-main-side-center {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1.5px solid #e5e5e5;
  }

  .dashboard-socail-links-text {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #08140c;
  }

  .main-step-account {
    display: grid;
    background: #fff;
    padding: 0.5rem;
    justify-content: end;
  }

  .main-step {
    // display: grid;
    background: #fff;
    padding: 45px 32px;
    margin-left: 0.313rem;
    margin-right: 0.313rem;

    //height: calc(100vh - 93px);
    @media screen and (max-width: 885px) {
      padding: 25px 12px;
    }
  }

  .btn-dash-main {
    width: 100%;
    border: none;
    padding: 0.5rem;
    background: #01903c;
    border-radius: 5px;
  }

  .btn-text-dash-main {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #ffffff;
  }

  .dashboard-add-more-account {
    //   display: grid;

    //  grid-template-columns: 1fr;
    //  justify-content: left;
  }

  .dashboard-collection-div {
    display: grid;
    padding: 1.2rem;
  }

  .dashboard-collection-text {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    /* Primary Color */

    color: #0866FF;
  }

  .dashboard-collection-text-review {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    /* Primary Color */

    color: #3BAEFE;
  }

  .dashboard-collection-text-funding {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    /* Primary Color */

    color: #ee151d;
  }

  .collection-root-conatiner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .collection-root-box {
    display: grid;
    background: #ffffff;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08),
      4px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    align-items: center;
    padding: 20px;
    gap: 36px;
    // width: 260px;
    // height: 142px;
  }

  .collection-root-box-body-root {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .collection-data-container {
    display: grid;
    // grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  .collection-root-icon {
    display: grid;
    align-content: center;
  }

  .collection-data-container-item1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #666666;
  }

  .collection-data-container-item2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    // white-space: nowrap;
    /* Deep Green */

    color: #061b01;
  }

  .collection-data-container-inactive {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #666666;
  }

  .tean-header-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #0866FF;
  }

  .dashboard-step-team-root {
    display: grid;
    width: 100%;
    min-height: 220px;
    grid-template-columns: 1fr 1fr 1fr;
    background: #ffffff;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08),
      4px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 0.625rem;
    padding: 3.125rem;
    grid-template-columns: auto auto auto;
    grid-gap: 0.5rem;

    @media screen and (max-width: 885px) {
      display: flex;
      flex-direction: column;
      padding: 0.625rem;
    }
  }

  .dashboard-overview {
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 2rem;
  }

  .dashboard-overview-root-1 {
    display: grid;
    justify-content: flex-start;
    margin-top: 0.5rem;
  }

  .btn-content-overview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dashboard-overview-root-2 {
    display: flex;
    justify-content: space-evenly;
  }

  .dashboard-over-btn {
    align-items: center;
    gap: 16px;

    width: 140px;

    border: 1px solid #cccccc;
    border-radius: 4px;
  }

  .datepicker {
    width: 148px;
    height: 41px;
    margin-right: 1rem;
    margin-left: 1rem;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background: #f5f5f5;

    .anticon svg {
      display: flex !important;
      color: #0866FF;
    }
  }

  :where(.css-dev-only-do-not-override-yp8pcc).ant-picker
    .ant-picker-input
    > input {
    // font-family: "Poppins";
    // font-style: normal;
    // font-weight: 500;
    // font-size: 14px;
    // line-height: 21px;
    // color: #666666;

    //use for now
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }

  .overView-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    color: #08140c;
  }

  .anticon svg {
    display: flex !important;
  }

  .btn-text-overview {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: rgba(0, 0, 0, 0.8);
  }

  .data-range-text-overview {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    margin-right: 1rem;
    color: #000000;
  }

  .data-from-text-overview {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    margin-right: 0.5rem;

    color: #000000;
  }

  .overview-main-root {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    gap: 11px;
    margin-top: -2rem;

    width: 100%;
    height: 108px;
    left: 316px;
    top: 202px;

    background: #ffffff;
    border: 1px solid #eeeeee;
    /* Card shadow */

    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 10px;

    img,
    svg {
      vertical-align: top;
    }
  }

  .overview-main-root-table {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // padding: 20px;
    gap: 11px;
    margin-top: -2rem;

    border-bottom: 1px solid #e5e5e5;

    width: 100%;
    height: 108px;
    left: 316px;
    top: 202px;

    img,
    svg {
      vertical-align: top;
    }
  }

  .inflow-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    /* identical to box height */

    color: #08140c;
  }

  .overview-main-root-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 1rem;
  }

  .overview-number {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    color: #061b01;
  }

  .mainDashboard .anticon svg {
    display: flex !important;
    color: black !important;
  }

  .overview-mute-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #666666;
    align-self: self-end;
  }

  .text-overview-hide {
    font-family: "Poppins";
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #666666;
  }

  .overview-chat-root {
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 40px;
    margin-top: 2rem;
  }

  .overview-chat {
    display: flex;
    height: 418px;
    width: 94%;
    background: #ffffff;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }

  .overview-chat-card {
    display: grid;
    width: 100%;
    height: 418px;

    grid-template-rows: 1fr 1fr 1fr;
    height: 418px;
    gap: 1rem;
  }

  .overview-card {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

    width: 326px;
    height: 126px;

    background: #fde7e8;
    border: 1px solid #ee151d;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
  }

  .overview-card-2 {
    /* Auto layout */
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

    width: 326px;
    height: 126px;

    background: #edf9f1;
    border: 1px solid #a5ce9b;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
  }

  .overview-card-3 {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

    width: 326px;
    height: 126px;

    background: #deebf4;
    border: 1px solid #3BAEFE;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
  }

  .overview-card-root-content {
    display: flex;
    padding: 0.5rem;
    width: 100%;
    gap: 8px;
    flex-direction: column;
    justify-content: space-between;
  }

  .overview-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .overview-card-header-item-1 {
    display: flex;
    align-items: flex-start;
  }

  .overview-card-header-item-2 {
    display: flex;
    align-items: flex-end;

    img,
    svg {
      vertical-align: -webkit-baseline-middle;
    }
  }

  .overview-card-header-item-text-mute {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    color: #666666;
  }

  .text-number-blod-overflow {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Header Text */

    color: #061b01;
  }

  .text-bottom-overview {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    /* Header Text */

    color: #061b01;
  }

  .trending-graph-container {
    height: 100%;
    width: 100%;
  }

  .btn-search-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 80px;
    height: 41px;

    background: #d2e7cd;
    border: 1px solid #0866FF;
    border-radius: 5px;
  }

  .btn-search-table-n {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 150px;
    height: 41px;

    background: #deebf4;
    border: 1px solid #3BAEFE;
    border-radius: 5px;
  }

  .btn-search-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #000000;
  }
}

.grid-auto {
  grid-template-rows: auto !important;
}

.setup-body-padding-bottom {
  padding-bottom: 9.5rem !important;
}
