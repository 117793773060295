.outstandingPayment {
  background: #F9F9F9;
  padding-inline: 30px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .outstandingPaymentRow1 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 30px 12px;

    .outstandingPaymentBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #666666;
      }

      > :nth-child(3) {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #666666;
      }

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #061B01;
      }
    }

    > :last-child {
      display: flex;
      align-items: center;
    }
  }

  .outstandingPaymentRow2 {
    border-radius: 5px;
    background: #FFFFFF;
    padding: 50px 47px;
    margin-top: 48px;

    > :first-child {
      color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      padding-bottom: 20px;
    }

    > :nth-child(3) {
      display: flex;
      gap: 3px;
      justify-content: right;

      > :first-child {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }

      > :nth-child(2) {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    > :nth-child(4) {
      padding-block: 20px 38px;
      display: flex;
      gap: 20px;
      align-items: center;

      .filterButton {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #000000;
          background: #3BAEFE;
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          padding: 8px 9px;
          gap: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      > :last-child {
        button {
          color: #000000;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 5px;
          border: 1px solid #CCC;
          background: #deebf4;
          padding: 8px 9px;
        }
      }
    }
  }
}


  .outstandingPaymentRow-details {
    width: 100%;
    border-radius: 5px;
    background: #FFFFFF;
    padding: 50px 47px;
    margin-top: 48px;


    .header-lhs{
      display: flex;
    gap: 1rem;

    .lhs-pending{
      display: flex;
     // align-items: center;
      justify-content: center;
      color: #E3B118;
      border-radius: 20px;
      padding: 0.5rem;
       font-size: 12px;
       background: #FEF5D7;


    }

       .lhs-failed{
      display: flex;
     // align-items: center;
      justify-content: center;
      color: #EE151D;
      border-radius: 20px;
      padding: 0.5rem;
       font-size: 12px;
       background: #FDE7E8;


    }

         .lhs-Successful{
      display: flex;
     // align-items: center;
      justify-content: center;
      color: #1F8505;
      border-radius: 20px;
      padding: 0.5rem;
       font-size: 12px;
       background: #D2E7CD;


    }

        .lhs-pending-2{
      display: flex;
     // align-items: center;
      justify-content: center;
      color: #0866FF;
     
      padding: 0.5rem;
       font-size: 12px;
       border: solid #0866FF 1px;
     

    }
    }

    .details-header{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .deatils-header-txt{
       color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      padding-bottom: 20px;
    }

       .deatils-header-txt-rhs{
        display: flex;
       color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      padding-bottom: 20px;
      margin-top: 3rem;
    }



        .deatils-header-txt{
       color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      padding-bottom: 20px;
    }


    .details-body{
      display: flex;

      gap: 1rem;
     
    }

    .details-body-lhs{
    border-radius: 5px;
    background: #FAFAFF;
    padding: 50px 47px;
    margin-top: 48px;
    width: 580px;
    height: 100%;
    }


        .details-body-rhs{
    border-radius: 5px;
    padding: 50px 47px;
  
    height: 100%;
    }

    .ddff{
      display: flex;
      flex-direction: column;
      width: 100%;
    }

  }