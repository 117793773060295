@use 'core/assets/sass/components/variables' as *;

.kycForm {
    background: var(--Neutral-Color1, #FFF);
    padding: 20px;
    max-width: 1094px;
    margin: auto;
    overflow-y: auto;
    border-radius: 8px;

    .kycFormRow {
      display: flex;

      > :first-child {
        flex-basis: 5%;
      }

      > :nth-child(2) {
        color: var(--text-700, #040434);
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        flex-basis: 95%;
      }
    }

    .kycFormRow1 {
        color: var(--text-700, #040434);
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .kycFormRow2 {
      margin-inline: auto;

        .onboardingDetails {
          display: flex;
          width: 100%;
          gap: 20px;

          > div {
            width: 50%;

            select option {
              background-color: #f0f0f0;
              color: #061b01 !important;
              opacity: 1;
              // font-size: 8px;
              // width: 400px;
            }
          }
        }

        .onboardingTerms {
          color: var(--text-500, #050649);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          display: flex;

          span {
            color: var(--secondary-blue-500, #3BAEFE);
          }
        }
    }

    .onboardingNextButton {
      text-align: center;
      display: grid;
      place-items: center;
      margin: 40px 0 20px 0;

      button {
        color: #FFF;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        border-radius: 4px;
        background: #0866FF;
        padding: 10px 48px;
        border: none;
        cursor: pointer;
        width: 400px;
        height: unset;

        &:disabled{
          background: $gray-700 !important;
          cursor: not-allowed !important;
        }
      }
    }

    .alreadyHave {
      text-align: center;
      color: var(--text-600, #050542);
      font-size: 14px;
      font-family: Inter;
      font-weight: 400;
      font-style: normal;
      line-height: 24px;

      span {
        color: var(--secondary-blue-500, #3BAEFE);
        cursor: pointer;
      }
    }
  }


  .touched-kyc-step {
    padding-left: 0.885rem;
    padding-right: 0.885rem;
    padding-top: 0.569rem;
    padding-bottom: 0.569rem;
    background: #0866FF;
    border: 0.25rem solid #89CEFE;
    color: $white;
    border-radius: 50%;
    min-width: 2.75rem;
  }
  
  .unTouched-kyc-step {
    padding-left: 0.885rem;
    padding-right: 0.885rem;
    padding-top: 0.569rem;
    padding-bottom: 0.569rem;
    background: #D1D5DB;
    border: 0.25rem solid #E5E7EB;
    color: #111827;
    border-radius: 50%;
    min-width: 2.75rem;
  }
 
  .kyc-line {
    width: 10rem;
    height: 0px;
    border-radius: 0.25rem;
    border: 0.125rem solid #E5E7EB;
  }

  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 20px;
    margin: 10px 0 ;
  }

  .ant-select-selector{
    height: 48px !important;
  }
  .ant-form-item-label{
    padding: 0 0 4px !important;
  }

  .step-btn-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:20px
    
  }

    .ant-btn {
      font-size: 14px;
      height: 48px;
      padding: 4px 15px;
      border-radius: 6px;
  
  
      &:hover,
      &:active {
        color: none !important;;
        // outline: none !important;
        // border: none !important;
  
        &:disabled {
          background-color: $gray-400 !important;
          color: $white !important;
        }
  
      }
    }

  .ant-btn-link {
    color: $color-primary
  }

  .ant-upload-select {

    display: block !important;
    width: 100% !important;
  
  }
