.mdaBalanceSheetPage {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .mdaBalanceSheetRow1 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 30px 12px;

    .mdaBalSheetBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
      }

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #08140C;
      }
    }

    > :last-child {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .mdaBalanceSheetRow2 {
    display: flex;
    justify-content: space-between;
    gap: 21px;
    padding-block: 25px 23px;

    .openingBalCard {
      background: #FDE7E8;
      border: 1px solid #EE151D;
      border-radius: 4px;
      padding-block: 14px 10px;
      padding-inline: 10px;
      width: 250px;
    }

    .totalInflowCard {
      background: #EEF9EB;
      border: 1px solid #A5CE9B;
      border-radius: 4px;
      padding-block: 14px 10px;
      padding-inline: 10px;
      width: 250px;
    }

    .totalOutflowCard {
      background: #deebf4;
      border: 1px solid #3BAEFE;
      border-radius: 4px;
      padding-block: 14px 10px;
      padding-inline: 10px;
      width: 250px;
    }

    .closingBalCard {
      background: #E7F7FB;
      border: 1px solid #3EC5E3;
      border-radius: 4px;
      padding-block: 14px 10px;
      padding-inline: 10px;
      width: 250px;
    }

    .mdaBalSheetRow2-cardTitle {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
      padding-block: 10px 6px;
    }

    .mdaBalSheetRow2-cardValue {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }


   .mdaBalanceStatementRow2 {
    display: flex;
    justify-content: flex-end;
    gap: 21px;
    padding-block: 25px 23px;

    .openingBalCard {
      background: #FDE7E8;
      border: 1px solid #EE151D;
      border-radius: 4px;
      padding-block: 14px 10px;
      padding-inline: 10px;
      width: 250px;
    }

    .totalInflowCard {
      background: #EEF9EB;
      border: 1px solid #A5CE9B;
      border-radius: 4px;
      padding-block: 14px 10px;
      padding-inline: 10px;
      width: 250px;
    }

    .totalOutflowCard {
      background: #deebf4;
      border: 1px solid #3BAEFE;
      border-radius: 4px;
      padding-block: 14px 10px;
      padding-inline: 10px;
      width: 250px;
    }

    .closingBalCard {
      background: #E7F7FB;
      border: 1px solid #3EC5E3;
      border-radius: 4px;
      padding-block: 14px 10px;
      padding-inline: 10px;
      width: 250px;
    }

    .mdaBalSheetRow2-cardTitle {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
      padding-block: 10px 6px;
    }

    .mdaBalSheetRow2-cardValue {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }

  .mdaBalanceSheetRow3 {
    background: #FFFFFF;
    border-radius: 4px;
    margin-block: 23px 19px;
    padding-block: 20px 16px;
    padding-inline: 20px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #08140C;
      border-bottom: 1px solid #E8E8E8;
      text-align: center;
      padding-bottom: 10px;
    }

    > :last-child {
      display: flex;
      justify-content: space-between;

      > div {
        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          padding-block: 10px 4px;
        }

        > :nth-child(2) {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #061B01;
        }
      }
    }
  }


    .mdaBalanceSheetRowView3 {
    background: #FFFFFF;
    border-radius: 4px;
    margin-block: 23px 19px;
    padding-block: 20px 16px;
    padding-inline: 20px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #08140C;
      border-bottom: 1px solid #E8E8E8;
      text-align: center;
      padding-bottom: 10px;
    }

     > :nth-child(2) {
   font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #08140C;
      border-bottom: 1px solid #E8E8E8;
      text-align: start;
      padding-bottom: 10px;
      padding: 1rem;
     }

        > :nth-child(3) {
   font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #08140C;
      border-bottom: 1px solid #E8E8E8;
      text-align: center;
      padding-bottom: 10px;
      padding: 1rem;
     }

    > :last-child {
      display: flex;
      justify-content: space-between;

      > div {
        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          padding-block: 10px 4px;
        }

        > :nth-child(2) {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #061B01;
        }
      }
    }
  }

  .mdaBalanceSheetRow4 {
    background: #FFFFFF;
    padding-inline: 20px;
    padding-block: 50px 30px;

    > :first-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;

      :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }

    > :nth-child(2) {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #061B01;
      padding-block: 36px 10px;
    }

    .mdaBalanceSheetSelectOptions {
      display: flex;
      align-items: center;
      gap: 15px;
      flex-wrap: wrap;
      padding-bottom: 30px;

      .ant-select-single .ant-select-selector {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
      }

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        background: #3BAEFE;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        padding: 8px;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }


    .mdaBalanceStatementRow4 {
    background: #FFFFFF;
    padding-inline: 20px;
    padding-block: 50px 30px;

    > :first-child {
           display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }

  
 
    }

    > :nth-child(2) {
         display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;

      :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }

       > :nth-child(3) {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #061B01;
      // padding-block: 36px 10px;
    }

    .mdaBalanceSheetSelectOptions {
      display: flex;
      align-items: center;
      gap: 15px;
      flex-wrap: wrap;
      padding-bottom: 30px;

      .ant-select-single .ant-select-selector {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
      }

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        background: #3BAEFE;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        padding: 8px;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.point{
  cursor: pointer;
}