.topMda {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .topMdaBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-block: 37px 25px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: normal;
      color: #666666;
    }

    > :nth-child(3) {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: normal;
      color: #666666;
    }

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: normal;
      color: #061B01;
    }
  }

  .topMdaRow2 {
    border-radius: 5px;
    background: #FFFFFF;
    padding: 50px 47px;

    > :first-child {
      color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      padding-bottom: 20px;
    }

    > :nth-child(3) {
      display: flex;
      gap: 3px;
      justify-content: right;
      padding-bottom: 48px;

      > :first-child {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }

      > :nth-child(2) {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
}
