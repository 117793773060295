//
// Layout Config
//

// scss-docs-start

// color-config
$white: #fff;
$black: #000;
$color-primary: #006cec;
$color-secondary: #a7a7a7;
$color-success: #6366F1;
$color-danger: #ed3a3a;
$color-warning: #ebbc2e;
$body-full-color: linear-gradient(180deg, #d2e7cd 0%, #C8E2F5 100%);

// Root font Sizes
$root-font-size: 13px; // Root font size for desktop mode
$root-font-size-lg: 13px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Footer
$footer-config: (
  // bg-color: '$color-primary',
) !default;
