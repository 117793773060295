.userHero {
  position: relative;
  width: 100%;
  height: 450px;
  background-image: url("../../imgs/src/homepagebubles.png");
  background-size: cover;
  background-position: center;
}

.videoModal {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: black;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.userHero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.userHeroImg {
  width: 484px;
  height: 301.68px;
}

@media only screen and (max-width: 885px) {
  .mobile-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.payment-links-body {
  margin-top: 7.5rem !important;
  margin-bottom: 11.875rem !important;
  padding-top: 2.563rem !important;
  padding-bottom: 6.438rem !important;
}

.equal-width {
  width: 33.33%;
  @media only screen and (max-width: 885px) {
    width: 100%;
  }
}

.mobile-flex {
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 885px) {
    display: flex;
    flex-direction: column;
  }
}

.dashboard-icon {
  width: 13px;
  height: 13px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.d-medium {
  display: none !important;
  @media only screen and (max-width: 885px) {
    display: block !important;
  }
}

.overflow-y-hide {
  overflow-y: scroll !important;
  overflow-x: hidden;
}
.center-all {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 6rem !important;
}
.collection-rules-input {
  width: 350px;
  height: 2.313rem;
  display: flex !important;
  align-items: center;
}

.multipleInput {
  border-radius: 0.25rem !important;
  width: 550px !important;
  background: #eef9eb;
  border: 2px dashed $text-muted;
  border-radius: 5px;
}

.border-none {
  border: none !important;
}

.borderTwo {
  border-top: 1px dotted #e8e8e8;
  border-bottom: 1px dotted #e8e8e8;
}

.payment-gateway-img {
  width: 100px;
  object-fit: cover;
  height: 1rem;
}
