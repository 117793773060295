.vatPayment {
  padding: 30px;
  overflow-y: auto;

  .vatPaymentBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  .vatPaymentRow1 {
    padding: 40px;
    border-radius: 5px;
    background: var(--neutral-color-1, #FFF);
    margin: auto;
    width: 580px;

    .payValueDiv {
      display: flex;

      > :first-child {
        flex-basis: 5%;
      }

      > :nth-child(2) {
        color: var(--Header-Text, #061B01);
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        flex-basis: 95%;
      }
    }

    .selectMethod {
      color: var(--Paragraph-Text-Color, #666);
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-block: 40px 10px;
    }

    .methodOfUpload {
      display: flex;
      gap: 20px;

      > div {
        label {
          width: 240px;
          height: 156px;
          border-radius: 5px;
          background: var(--neutral-color-1, #FFF);
          padding-inline: 16px;
          cursor: pointer;

          .inputDiv {
            padding-block: 10px;
            text-align: right;
          }
        }

        .taxType {
          padding-top: 10px;
          color: var(--paragraph-text-color, #666);
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .vatProceedButton {
      padding-top: 40px;
      text-align: center;

      button {
        color: #FFF;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 4px;
        background: var(--primary-color, #0866FF);
        padding: 12px 48px;
        border: none;
      }
    }
  }

  .vatSingleKeyIn {
    background: var(--Neutral-Color1, #FFF);
    padding: 40px;
    max-width: 900px;
    margin: auto;
    overflow-y: auto;

    .vatSingleKeyInRow1 {
      display: flex;

      > :first-child {
        flex-basis: 5%;
      }

      > :nth-child(2) {
        color: var(--Header-Text, #061B01);
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        flex-basis: 95%;
      }
    }

    .vatSingleKeyInForm {
      padding-block: 40px;

      .selectFundingDiv {
        > :first-child {
          color: #061B01;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-bottom: 10px;
        }

        > :nth-child(2) {
          display: flex;
          width: 100%;
          gap: 20px;

          > div {
            width: 50%;
          }
        }
      }

      .beneficiaryDetails {
        width: 100%;

        > :first-child {
          color: #666666;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-top: 20px;
        }

        .detailsRow {
          display: flex;
          width: 100%;
          gap: 20px;

          > div {
            width: 50%;
          }
        }
      }
    }

    .vatSingleKeyInSubmitButton {
      text-align: center;

      button {
        color: #FFF;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 4px;
        background: #0866FF;
        padding: 12px 48px;
        border: none;
        cursor: pointer;
      }
    }
  }

  .vatBulkUploadDiv {
    margin: auto;
    background: #FFFFFF;
    padding: 40px;
    width: 480px;

    .vatBulkUpload {
      display: flex;
      padding-bottom: 20px;

      > :first-child {
        flex-basis: 5%;
      }

      .vatUploadText {
        flex-basis: 95%;
        color: var(--Header-Text, #061B01);
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .downloadTemplate {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #999999;
      padding-bottom: 10px;
      gap: 8px;
    }

    .vatFileUploadDiv {
      padding-block: 20px 40px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }

      label {
        display: flex;
        gap: 20px;
        align-items: center;

        > :first-child {
          background: #deebf4;
          border-radius: 4px;

          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #3BAEFE;
          padding: 12px 34px;
          border: none;
          display: inline-block;
          cursor: pointer;
        }

        > :nth-child(2) {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #666666;
          word-wrap: break-word;
          width: 240px;
        }
      }

      > :last-child {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.280702px;
        color: #AAAAAA;
        padding-top: 5px;
      }
    }

    .vatUploadButton {
      text-align: center;

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #FFFFFF;
        background: #0866FF;
        border-radius: 4px;
        padding: 12px 34px;
        border: none;
      }
    }
  }

  .pensionPaymentTable {
    .pensionPaymentTableRow1 {
      display: flex;
      gap: 40px;
      justify-content: right;
      padding-block: 40px;

      > :first-child {
        button {
          color: var(--Primary-Color, #0866FF);
          text-align: center;
          font-family: Poppins, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 4px;
          border: 1px solid var(--Primary-Color, #0866FF);
          padding: 12px 34px;
          cursor: pointer;
        }
      }

      > :nth-child(2) {
        button {
          color: #FFF;
          text-align: center;
          font-family: Poppins, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 4px;
          background: var(--Primary-Color, #0866FF);
          padding: 12px 34px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}







.tpSuccessModal {
  display: grid;
  gap: 40px;
  padding-inline: 30px;
  //justify-content: center;

  .tpSuccessModalTitle {
    color: var(--Header-Text, #061B01);
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .tpSuccessModalDesc {
    color: var(--Paragraph-Text-Color, #666);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .tpSuccessModalActionButton {
    margin: auto;

    button {
      color: #FFFFFF;
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 4px;
      background: var(--Primary-Color, #0866FF);
      padding: 12px 48px;
      border: none;
      cursor: pointer;
    }
  }

  .tpSuccessModalRow1 {
    display: flex;

    > :first-child {
      flex-basis: 5%;
    }

    > :nth-child(2) {
      color: var(--Header-Text, #061B01);
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      flex-basis: 95%;
    }
  }
}

th.ant-table-cell {
  background: #F2F2F2 !important;
}

