.bulkPayments {
  height: 100%;
  overflow-y: auto;
  padding: 40px 35px;

  .bulkpaymentsBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    padding-block: 23px 30px;
  }

  .bpTabs {
    background: #FFFFFF;
    border: 1.5px solid #E5E5E5;
    padding-inline: 20px;
    padding-block: 0 60px;

    .ant-tabs-tab-btn {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #666666 !important;
    }

    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #061B01 !important;
    }

    .ant-tabs .ant-tabs-ink-bar {
      background: #0866FF !important;
    }
  }

  .payByFileUpload {
    .bulkPaymentDragAndDrop {
      display: grid;
      justify-items: center;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #08140C;
        padding-block: 60px 20px;
      }

      > :nth-child(2) {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #999999;
        padding-bottom: 10px;
        gap: 8px;
      }

      label {
        border: 2px dashed #61CD85;
        background: #EDF9F1;
        width: 400px;
        display: grid;
        justify-items: center;
        padding: 34px;
        border-radius: 4px;

        .dragDrop {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #08140C;
          padding-block: 10px 4px;
        }

        .fileFormat {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          color: #999999;
        }

        .orText {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          color: #666666;
          padding-block: 10px;
        }

        .browseFile {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #48B460;
          background: #FFFFFF;
          border: 1px solid #48B460;
          border-radius: 5px;
          padding: 4px 10px;
        }

        .size {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          color: #999999;
        }
      }
    }

    .paymentSummary {
      padding-inline: 47px;

      .psRow1 {
        border: 0.5px solid #E8E8E8;
        border-radius: 4px;
        padding-inline: 50px;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: #08140C;
          padding-block: 18px 16px;
          border-bottom: 1px solid #E8E8E8;
        }

        > :nth-child(2) {
          display: grid;
          justify-content: right;
          padding-block: 16px;
          border-bottom: 1px solid #E8E8E8;

          > :first-child {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #0866FF;
            padding-bottom: 7px;
          }

          > :nth-child(2) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
          }
        }

        > :last-child {
          display: flex;
          justify-content: space-between;
          padding-block: 15px;

          .psTitle {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
            padding-bottom: 4px;
          }

          .psValue {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #061B01;
          }
        }
      }

      .psRow2 {
        display: flex;
        justify-content: space-between;
        padding-block: 40px 30px;

        > :first-child {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        .addFilterButton {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #666666;
          background: #f6eeff;
          border-radius: 4px;
          padding: 10px;
          border: none;
          display: flex;
          gap: 13px;
          align-items: center;
        }

        .resetButton {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          border: 1px solid #CCCCCC;
          border-radius: 5px;
          padding: 10px;
          background: #deebf4;
        }

        .submitButton {
          button {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
            background: #0866FF;
            border-radius: 4px;
            padding: 12px 40px;
            border: none;

            &:disabled {
              cursor: not-allowed;
              background: #898989;
            }
          }
        }
      }

      .psRow3 {
        .ant-table-content {
          width: 100%;
          overflow-x: auto;
        }

        th.ant-table-cell {
          background: #f6eeff;
        }

        //input.ant-checkbox-input {
        //  background: #0866FF;
        //}
      }
    }
  }

  .payByAccumulation {
    margin-top: 50px;
    padding-inline: 47px;

    .pbaRow1 {
      border: 0.5px solid #E8E8E8;
      border-radius: 4px;
      padding-inline: 50px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #08140C;
        padding-block: 18px 16px;
        border-bottom: 1px solid #E8E8E8;
      }

      > :nth-child(2) {
        display: grid;
        justify-content: right;
        padding-block: 16px;
        border-bottom: 1px solid #E8E8E8;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          color: #0866FF;
          padding-bottom: 7px;
        }

        > :nth-child(2) {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666666;
        }
      }

      > :last-child {
        display: flex;
        justify-content: space-between;
        padding-block: 15px;

        .psTitle {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          padding-bottom: 4px;
        }

        .psValue {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #061B01;
        }
      }
    }

    .pbaRow2 {
      display: flex;
      justify-content: space-between;
      padding-block: 40px 30px;

      > :first-child {
        display: flex;
        gap: 10px;
        align-items: center;

        > div {
          button {
            border: none;
          }
        }
      }

      > :last-child {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .addEntryButton {
          button {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #0866FF;
            padding: 12px 40px;
            border: 1px solid #0866FF;
            border-radius: 4px;
          }
        }

        .pbaSubmitButton {
          button {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
            background: #0866FF;
            border-radius: 4px;
            padding: 13px 45px;
            border: none;
          }
        }
      }
    }

    .pbaRow3 {
      th.ant-table-cell {
        background: #f6eeff;
      }

    }
  }
}


.pbaInformationModal {
  display: grid;
  padding-inline: 30px;
  padding-block: 50px;

  .pbaInformationModalTitle {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #08140C;
    padding-top: 40px;
  }

  .pbaInformationModalInstruction {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #08140C;
    padding-block: 36px;
  }

  > :last-child {
    text-align: center;
    padding-top: 36px;

    button {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #FFFFFF;
      padding: 12px 65px;
      border: none;
      background: #999999;
      border-radius: 5px;
    }
  }
}
    .psRow1 {
        border: 0.5px solid #E8E8E8;
        border-radius: 4px;
        padding-inline: 50px;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: #08140C;
          padding-block: 18px 16px;
          border-bottom: 1px solid #E8E8E8;
        }

        > :nth-child(2) {
          display: grid;
          justify-content: right;
          padding-block: 16px;
          border-bottom: 1px solid #E8E8E8;

          > :first-child {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #0866FF;
            padding-bottom: 7px;
          }

          > :nth-child(2) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
          }
        }

        > :last-child {
          display: flex;
          justify-content: space-between;
          padding-block: 15px;

          .psTitle {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
            padding-bottom: 4px;
          }

          .psValue {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #061B01;
          }
        }
      }