.retailVendorPage {
  background: #F9F9F9;
  height: 100%;
  overflow-y: auto;
  padding: 30px;

  .retailVendorBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    padding-block: 23px;
    //border-bottom: 1px solid #CCCCCC;
  }

  .retailVendorForm {
    display: grid;
    align-items: center;
    padding: 40px;
    width: 480px;
    margin-inline: auto;
    background: #FFFFFF;
    text-align: center;

    .no-outline {
      width: 100% !important;
    }

    .rvTitle {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #000000;
    }


    .beneficiaryDetails {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
      text-align: left;
      padding-top: 30px;
    }
  }

  .retailVendorConfirmDetails {
    //display: grid;
    //align-items: center;
    //padding: 40px;
    //gap: 40px;
    //width: 480px;
    //background: #FFFFFF;
    //margin-inline: auto;

    //font-family: 'Poppins', sans-serif;
    //font-style: normal;
    //font-weight: 400;
    //font-size: 14px;
    //line-height: 21px;
    //color: #666666;

    .confirmDetailTitle {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #000000;
    }

    > div {
      text-align: left;

      > div {
        display: grid;
        padding: 20px;

        span {
          display: block;
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #AAAAAA;
        }
      }
    }

    .vrActionButtons {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

}

.rvpConfirmationModal {
  display: grid;
  gap: 40px;
  padding-inline: 30px;
  justify-content: center;

  .rvpConfirmationModalTitle {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #08140C;
    text-align: center;
  }

  .rvpConfirmationModalActionButton {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}


.tableRowErrorHighlighted {
  background-color: rgba(255, 175, 175, 0.21);
}
