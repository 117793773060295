.businessPreference {
  padding: 10px 30px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  padding-block: 23px;
  border-bottom: 1px solid #cccccc;
}

.navCards {
  width: 100%;
  display: flex;
  gap: 17px;

  .navCard {
    display: grid;
    padding: 24px 20px 40px;
    gap: 15px;
    width: 348px;
    height: 138px;
    background: #ffffff;
    border-bottom: 2px solid #ffde71;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #666666;

    .navCardTitle {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
    }

    .navCardDescription {
      @media ((min-width: 360px) and (max-width: 500px)) {
        display: none;
      }
    }
  }

  @media ((min-width: 600px) and (max-width: 800px)) {
    display: none;
  }

  @media (width < 360px) {
    display: none;
  }
}

.navCardsMobile {
  display: grid;
  place-items: center;
  select {
    padding: 6px 12px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    max-width: 350px;
    font-size: 1.2em;
    background: #f4f4f4;
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: none;

    @media ((min-width: 600px) and (max-width: 800px)) {
      display: block;
    }

    @media (width < 360px) {
      display: block;
    }
  }
}

.notificationSettings {
  display: grid;
  gap: 60px;
  margin-top: 60px;

  .security2FAHeader {
    border-bottom: 1px solid #cccccc;
    > div {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #061b01;
      width: fit-content;
      padding-block: 10px;
      border-bottom: 4px solid #3BAEFE;
    }
  }

  .security2FARow {
    display: flex;
    gap: 100px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e8e8e8;

    > :first-child {
      display: grid;
      gap: 8px;
      max-width: 275px;

      > div {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #061b01;
      }

      > span {
        display: block;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
      }
    }

    > :last-child {
      display: grid;
      gap: 20px;

      > div {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }

    @media (width < 700px) {
      display: grid;
      gap: 20px;
    }
  }
}

.security2FA {
  display: grid;
  gap: 60px;
  margin-top: 60px;

  .security2FAHeader {
    border-bottom: 1px solid #cccccc;
    > div {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #061b01;
      width: fit-content;
      padding-block: 10px;
      border-bottom: 4px solid #ee151d;
    }
  }

  .security2FARow {
    display: grid;
    grid-template-columns: 50% auto;
    gap: 100px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e8e8e8;

    select {
      padding: 6px 12px;
      box-sizing: border-box;
      width: 211px;
      height: 33px;
      background: #f4f4f4;
      border: 1px solid #cccccc;
      border-radius: 5px;
    }

    > :first-child {
      display: grid;
      gap: 8px;
      //max-width: 50%;

      > div {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #061b01;
      }

      > span {
        display: block;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
      }
    }

    > :last-child {
      display: grid;
      gap: 20px;

      > div {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }
}

.adminBody {
  padding: 10px 30px;
  box-sizing: border-box;
  height: 100%;
  background-color: #f9f9f9;
  height: 100%;
  overflow-y: auto;

}
