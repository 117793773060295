.oagfCollectionView {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .oagfCollectionViewRow1 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 30px 12px;

    .oagfCollectionViewBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #666666;
      }

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #061B01;
      }
    }

    > :last-child {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .oagfCollectionViewRow2 {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 50px;

    > div {
      padding: 18px;
      border-radius: 4px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);

      > :first-child {
        color: #0866FF;
        font-family: Poppins, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        text-align: right;
        cursor: pointer;
      }

      > :nth-child(3) {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-block: 16px;
      }

      > :nth-child(4) {
        display: flex;
        gap: 7px;
        align-items: center;
        padding-bottom: 8px;

        > :first-child {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        > :last-child {
          color: #999;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      > :nth-child(5) {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-top: 1.5px solid #CCCCCC;
        padding-block: 5px;
      }

      > :last-child {
        color: #666;
        font-family: Poppins, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .oagfCollectionViewRow3 {
    display: grid;
    grid-template-columns: 50% 40%;
    //justify-content: space-between;
    gap: 10%;
    padding-block: 48px;

    > div {
      border-radius: 4px;
      border: 1px solid #CCCCCC;
      background: #FFFFFF;
      padding: 20px 28px;

      > :first-child {
        display: flex;
        justify-content: space-between;
        //gap: 100px;
        //justify-content: center;
        //align-items: center;

        .topMda {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .report {
          display: flex;
          gap: 13px;
          justify-content: center;
          align-items: center;

          > :first-child {
            color: #0866FF;
            font-family: Poppins, sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
          }
        }
      }
    }

    > :nth-child(2) {
      border-radius: 4px;
      border: 1px solid #3EC5E3;
      background: #E7F7FB;
      padding: 20px 30px;
    }
  }

  .oagfCollectionViewRow4 {
    border: 1px solid var(--green-2, #A5CE9B);
    background: #EDF9F1;
    padding: 20px 30px;

      > :first-child {
        display: flex;
        //gap: 100px;
        justify-content: space-between;

        .topMdaSector {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .report {
          display: flex;
          gap: 13px;
          justify-content: center;
          align-items: center;

          > :first-child {
            color: #0866FF;
            font-family: Poppins, sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
        }
      }
    }
  }
}
