.onboarding{
  background: var(--neutral-white-500, #F0F0FF);
  padding-block: 40px 66px;
  // height: 100vh;
  height: 100%;
  overflow-y: auto;

  .SimpliPayLogo {
    text-align: center;
    padding-bottom: 50px;
  }

  .onboardingForm {
    background: var(--Neutral-Color1, #FFF);
    padding: 40px;
    max-width: 900px;
    margin: auto;
    overflow-y: auto;
    border-radius: 8px;

    .onboardingFormRow {
      display: flex;

      > :first-child {
        flex-basis: 5%;
      }

      > :nth-child(2) {
        color: var(--text-700, #040434);
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        flex-basis: 95%;
      }
    }

    .onboardingFormRow1 {
        color: var(--text-700, #040434);
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .onboardingFormRow2 {
      padding-block: 40px;
      margin-inline: auto;

        .onboardingDetails {
          display: flex;
          width: 100%;
          gap: 20px;

          > div {
            width: 50%;

            select option {
              background-color: #f0f0f0;
              color: #061b01 !important;
              opacity: 1;
              // font-size: 8px;
              // width: 400px;
            }
          }
        }

        .onboardingTerms {
          color: var(--text-500, #050649);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          display: flex;

          span {
            color: var(--secondary-blue-500, #3BAEFE);
          }
        }
    }

    .onboardingNextButton {
      text-align: center;
      padding-bottom: 40px;
      display: grid;
      place-items: center;

      button {
        color: #FFF;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        border-radius: 4px;
        background: #0866FF;
        padding: 10px 48px;
        border: none;
        cursor: pointer;
        width: 400px;
        height: unset;
      }
    }

    .alreadyHave {
      text-align: center;
      color: var(--text-600, #050542);
      font-size: 14px;
      font-family: Inter;
      font-weight: 400;
      font-style: normal;
      line-height: 24px;

      span {
        color: var(--secondary-blue-500, #3BAEFE);
        cursor: pointer;
      }
    }
  }



  .starterBusiness {
    display: flex;
    width: 100%;
    gap: 20px;
  }



  .requiredDocument {
    background: var(--Neutral-Color1, #FFF);
    padding: 40px;
    max-width: 900px;
    margin: auto;
    overflow-y: auto;
    border-radius: 8px;

    .requiredDocumentRow1 {
      display: flex;

      > :first-child {
        flex-basis: 5%;
      }

      > :nth-child(2) {
        color: var(--text-700, #040434);
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        flex-basis: 95%;
      }
    }

    .requiredDocumentRow2 {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #000;
      padding-top: 30px;
    }

    .documentTitle {
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      color: var(--secondary-blue-800, #20608C);
      padding-top: 40px;
    }

    .bullet-list {
      list-style-type: disc; /* Change this for different bullet styles */
      margin-bottom: 0 !important;
    }

    .listStyle {
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: #000;
    }

    .cac {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--text-400, #37386D);
      padding-bottom: 10px;
    }

    .requiredDocumentButton {
      text-align: center;
      padding-top: 40px;

      button {
        color: #FFF;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        border-radius: 4px;
        background: #0866FF;
        padding: 10px 48px;
        border: none;
        cursor: pointer;
        width: 400px;
      }
    }
  }

  .businessSetupSteps {
    background: var(--Neutral-Color1, #FFF);
    padding: 40px;
    max-width: 900px;
    margin: auto;
    overflow-y: auto;
    border-radius: 8px;

    .businessSetupStepsRow1 {
      display: flex;

      > :first-child {
        flex-basis: 5%;
      }

      > :nth-child(2) {
        color: var(--text-700, #040434);
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        flex-basis: 95%;
      }
    }

    .businessSetupStepsRow2 {
     

      .stepTitle {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #0866FF !important;
      }
    }
  }
}


.ant-steps-item-title {
  color: #0866FF !important;
}

.ant-steps-item-icon {
  background-color: #E6EFFF !important;
  border-color: #974DE8 !important;
}

.ant-steps-icon {
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #4B5563 !important;
}

.ant-steps {
  padding-block: 40px !important;
}


.businessTypeCard {
  display: grid;
  width: 240px;
  height: 132px;
  border-radius: 5px;
  color: #5E718D;
  background-color: #ffffff;
  border: solid 1px #CCCCCC;
  padding: 10px;
  cursor: pointer;

  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    color: #0866FF;
    background-color: #E6EFFF;
    border: solid 1px #0866FF;
    &* {
      fill: #0866FF !important;
    }
  }
}


.userRolesRadioButton {
  display: grid;
  gap: 20px;
  padding-bottom: 36px;

  label {
    color: #666666;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .checkbox-dropdown {
    display: grid;
    gap: 13px;
    padding-left: 30px;

    label {
      display: flex;
      align-content: center;
      color: #666666;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    input {
      margin-right: 15px;
    }
  }
}
