//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

//Theme colors
$white: #ffffff !default;
$color-primary: #0866FF;
$color-secondary: #999999;
$color-success: #0866FF;
$success-box: #B4D1FF;
$alt-color-success: #40a060;
$color-danger: #ee151d;
$color-warning: #ebbc2e;
$dark: #050649;
$dark2: #111827;
$bold: #3d4a5c;
$brown: #645116;
$footer-button: #3BAEFE;
$scroll: #B4D1FF;
$black: #000000;
$text-danger: #fde7e8;
$unchecked-switch: #e8e8e8;
$pending: #deebf4;
$pending-text: #e3b118;
$gray-100: #030d00;
$gray-200: #333333;
$gray-300: #353d33;
$gray-400: #686e66;
$gray-500: #6B7280;
$gray-600: #9a9e99;
$gray-700: #999999;
$gray-800: #cccccc;
$gray-900: #ffffff;
$text-muted: $gray-500 !default;
$body-color: $gray-900 !default;
$body-bg: $white;
$form-bg: #f9f9f9;
$form-border: #e5e5e5;
$light-grey: #e3e3e3;
$edit: #0da4c6;
$primary-500: #5e718d;
$alt-gray: #f4f4f4;
$light-success: #d2e7cd;
$collection-button: #aaaaaa;

// Light colors
$light: $gray-100 !default; // Bootstrap variable
$light-active: $gray-300 !default; // Custom variable
$light-inverse: $gray-800 !default; // Custom variable
$lighten: rgba($light, 0.5); // Custom variable

// Primary colors
$primary: #3699ff !default; // Bootstrap variable
$primary-active: #187de4 !default; // Custom variable
$primary-light: #212e48 !default; // Custom variable
$primary-inverse: #40a060 !default; // Custom variable

// Success
$success: #0bb783;
$success-active: #04aa77;
$success-light: #1c3238;
$success-inverse: #ffffff;

$success-notification-background: rgba(31, 133, 5, 0.1);
$info-notification-background: rgba(47, 128, 237, 0.2);

$warning-notification-background: rgba(235, 188, 46, 0.2);
$danger-notification-background: rgba(237, 58, 58, 0.2);

// Info
$info: #8950fc;
$info-active: #7337ee;
$info-light: #2f264f;
$info-inverse: #ffffff;

// Danger
$danger: #f64e60;
$danger-active: #ee2d41;
$danger-light: #3a2434;
$danger-inverse: #ffffff;

// Warning
$warning: #ffa800;
$warning-active: #ee9d01;
$warning-light: #392f28;
$warning-inverse: #ffffff;

$theme-colors: (
  "white": $white,
  // custom color type
  "light": $light,
  "primary": $color-primary,
  "secondary": $color-secondary,
  "success": $color-success,
  "alt-success": $alt-color-success,
  "warning": $color-warning,
  "danger": $color-danger,
  "info": $info,
  "dark": $dark,
  "dark2": $dark2,
  "brown": $brown,
  "footer": $footer-button,
  "warning-alt": $scroll,
  "black": $black,
  "gray": $form-border,
  "edit": $edit,
  "primary-500": $primary-500,
  "gray-500": $gray-500,
  "alt-gray": $alt-gray,
  "light-success": $light-success,
  "collection-button": $collection-button,
  "admin-body": $form-bg,
  "unchecked": $unchecked-switch,
  "pending": $pending,
  "alt-red": $text-danger,
) !default;

// Text colors
$theme-text-colors: (
  "white": $white,
  "primary": $color-primary,
  "secondary": $color-secondary,
  "success": $color-success,
  "alt-success": $alt-color-success,
  "warning": $color-warning,
  "danger": $color-danger,
  "muted": $text-muted,
  "dark": $dark,
  "dark2": $dark2,
  "brown": $brown,
  "gray-800": $gray-800,
  "gray": $form-border,
  "edit": $edit,
  "pending": $pending-text,
  "label-text": $collection-button,
  "alt-red": $text-danger // "bold": $bold,,,,,,,,,,,,,,,,,,,,
) !default;

// Bootstrap gray colors map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
  "light": $light-grey,
) !default; // Custom variable

// Bootstrap custom colors
$blue: #009ef6 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// Bootstrap colors map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "gray": $gray-600,
  "gray-dark": $gray-800,
  "alt-red": $text-danger,
) !default;

// Notification background colors
$theme-notification-background-colors: (
  "primary": $color-primary,
  "warning ": $warning-notification-background,
  "success": $success-notification-background,
  "info": $info-notification-background,
  "danger": $danger-notification-background,
) !default;

$transition-link: color 0.2s ease, background-color 0.2s ease !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Font family
$font-family-sans-serif: Poppins, Helvetica, "sans-serif" !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg: $font-size-base * 1.075 !default; // 14.04px
$font-size-sm: $font-size-base * 0.925 !default; // 12.025px

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-bolder: 600 !default;
$font-weight-boldest: 700 !default;

$font-weight-base: $font-weight-normal !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: $gray-200 !default;

$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;

$rounded-pill: 50rem !default;

$component-active-color: $primary-inverse !default;
$component-active-bg: $primary !default;
$component-hover-bg: $primary-light !default;
$component-hover-color: $primary !default;

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;
$transition-link: color 0.2s ease, background-color 0.2s ease !default; // Custom variable
$transition-input: color 0.2s ease, background-color 0.2s ease !default; // Custom variable

// Border Radiues
$border-radius-sm: 0.275rem !default;
$border-radius: 0.475rem !default;
$border-radius-lg: 0.775rem !default;
$border-radius-xl: 1rem !default; // Custom variable

// Line heights
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 1.75 !default;
$line-height-xl: 2 !default;
$line-height-xxl: 2.25 !default;

// Components
//
// Define common padding and border radius sizes and more.

$component-active-color: $primary-inverse !default;
$component-active-bg: $primary !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 1rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base * 1.1 !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
) !default;
$input-btn-focus-box-shadow: null !default;

$input-placeholder-color: $gray-500 !default;
$input-plaintext-color: $body-color !default;

$input-btn-padding-y-sm: 0.55rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
$input-btn-font-size-sm: $font-size-sm !default;

$input-btn-padding-y-lg: 0.825rem !default;
$input-btn-padding-x-lg: 1.5rem !default;
$input-btn-font-size-lg: $font-size-base * 1.15 !default;

$input-btn-border-width: $border-width !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius !default;
$btn-border-radius-lg: $border-radius !default;

// Forms
$form-label-margin-bottom: 0.5rem !default;
$form-label-font-size: 1.05rem !default;
$form-label-font-weight: 500 !default;
$form-label-color: $gray-800 !default;

$form-text-margin-top: 0.5rem !default;
$form-text-font-size: $font-size-sm !default;
$form-text-color: $text-muted !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-bold !default;
$input-line-height: $input-btn-line-height !default;

$input-solid-bg: $form-bg !default; // Custom variable
$input-solid-border: $form-border !default; // Custom variable
$input-solid-bg-focus: darken($form-bg, 2%) !default; // Custom variable
$input-solid-placeholder-color: $input-placeholder-color !default; // Custom variable
$input-solid-color: $dark !default; // Custom variable

$input-bg: $body-bg !default;
$input-disabled-bg: $gray-200 !default;
$input-readonly-bg: $input-bg !default; // Custom variable
$input-disabled-border-color: null !default;

$input-color: $gray-500 !default;
$input-border-color: $gray-300 !default;
$input-border-width: $input-btn-border-width !default;

$input-border-radius: $btn-border-radius !default;
$input-border-radius-sm: $btn-border-radius-sm !default;
$input-border-radius-lg: $btn-border-radius-lg !default;

$input-focus-border-color: $gray-400 !default;
$input-focus-color: $input-color !default;

$form-check-input-bg: $body-bg !default;
$form-check-input-bg-solid: $gray-500 !default; // Custom variable
$form-check-input-border: 1px solid $gray-500 !default;
$form-check-input-border-radius: 0.45em !default;
$form-check-radio-border-radius: 50% !default;
$form-check-input-focus-border: $input-focus-border-color !default;
$form-check-input-focus-box-shadow: none !default;

$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $white !default;
$form-check-input-checked-border-color: $gray-500 !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'><path d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='#{$form-check-input-checked-color}'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-unchecked-color: $gray-500 !default;
$form-check-input-unchecked-bg-color: $white !default;
$form-check-input-unchecked-border-color: $gray-500 !default;
$form-check-input-unchecked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'><path d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='#{$form-check-input-unchecked-color}'/></svg>") !default;
$form-check-radio-unchecked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-unchecked-color}'/></svg>") !default;

// Opacity
$opacity-values: (
  0: 0,
  5: 0.05,
  10: 0.1,
  15: 0.15,
  20: 0.2,
  25: 0.25,
  50: 0.5,
  75: 0.75,
  100: 1,
) !default;

// Zindex
$zindex-values: (
  n1: -1,
  n2: -2,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
) !default;

// Letter spacing
$letter-spacing-values: (
  1: 0.1rem,
  2: 0.115rem,
  3: 0.125rem,
  4: 0.25rem,
  5: 0.5rem,
) !default;

// Custom hight and width sizes
$custom-sizes: (
  unset: unset,
  1r: 1rem,
  2r: 2rem,
  3r: 3rem,
  1r1: 1.125rem,
  1r5: 1.5rem,
  2e: 0.115rem,
  3e: 0.125rem,
  4r: 0.25rem,
  5r: 0.5rem,
  15r: 0.938rem,
  10r: 0.625rem,
  11r5: 0.719rem,
  5: 5%,
  10: 10%,
  15: 15%,
  25: 25%,
  30: 30%,
  35: 35%,
  50: 50%,
  65: 65%,
  70: 70%,
  75: 75%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%,
  auto: auto,
  1px: 1px,
  2px: 2px,
  3px: 3px,
  4px: 4px,
  5px: 5px,
  6px: 6px,
  7px: 7px,
  8px: 8px,
  9px: 9px,
  10px: 10px,
  14px: 0.875rem,
  15px: 15px,
  18px: 18px,
  20px: 20px,
  24px: 24px,
  25px: 25px,
  25e: 1.563rem,
  30px: 30px,
  35px: 35px,
  35e: 2.188rem,
  38e: 2.375rem,
  40px: 40px,
  41: 2.563rem,
  42: 2.625rem,
  44: 2.75rem,
  45px: 45px,
  45e: 2.813rem,
  49e: 4.938rem,
  50px: 50px,
  51: 3.188rem,
  55px: 55px,
  60px: 60px,
  65px: 65px,
  66px: 66px,
  70px: 70px,
  75px: 75px,
  79e: 4.938rem,
  80px: 80px,
  80e: 5rem,
  84: 5.25rem,
  85px: 85px,
  87e: 5.438rem,
  90px: 90px,
  90e: 5.625rem,
  95px: 95px,
  100px: 100px,
  110: 6.875rem,
  120: 7.5rem,
  125px: 125px,
  126: 7.875rem,
  148: 9.25rem,
  150px: 150px,
  152: 9.5rem,
  175px: 175px,
  180: 11.25rem,
  191: 11.938rem,
  200px: 200px,
  200e: 12.5rem,
  220: 13.75rem,
  220px: 220px,
  225px: 225px,
  231e: 14.445rem,
  250px: 250px,
  262e: 16.375rem,
  275px: 275px,
  300px: 300px,
  325px: 325px,
  326e: 20.375rem,
  349e: 21.813em,
  350px: 350px,
  374px: 374px,
  375px: 375px,
  400px: 400px,
  400: 25rem,
  419r: 26.188em,
  420px: 420px,
  425px: 425px,
  450px: 450px,
  475px: 475px,
  484px: 484px,
  492e: 30.75rem,
  500px: 500px,
  522e: 32.625rem,
  538e: 33.5rem,
  550px: 550px,
  600px: 600px,
  600e: 37.53em,
  631px: 631px,
  650px: 650px,
  700px: 700px,
  750px: 750px,
  800px: 800px,
  850px: 850px,
  900px: 900px,
  950px: 950px,
  1000px: 1000px,
) !default;

// Symbol Sizes
$symbol-sizes: (
  10px: 10px,
  15px: 15px,
  18px: 18px,
  20px: 20px,
  25px: 25px,
  30px: 30px,
  35px: 35px,
  40px: 40px,
  45px: 45px,
  default: 50px,
  50px: 50px,
  55px: 55px,
  60px: 60px,
  65px: 65px,
  70px: 70px,
  75px: 75px,
  100px: 100px,
  125px: 125px,
  150px: 150px,
  160px: 160px,
  175px: 175px,
  200px: 200px,
) !default;

//viewport
$viewport-sizes: (
  unset: unset,
  5: 5vh,
  10: 10vh,
  15: 15vh,
  25: 25vh,
  30: 30vh,
  35: 35vh,
  50: 50vh,
  65: 65vh,
  70: 70vh,
  75: 75vh,
  80: 85vh,
  85: 85vh,
  90: 90vh,
  95: 95vh,
  100: 100vh,
) !default;

// Bullet
$bullet: (
  bg-color: $gray-400,
  dot-size: 4px,
  bar-height: 4px,
  bar-width: 8px,
  bar-border-radius: 6px,
  line-height: 1px,
  line-width: 5px,
) !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;

// stylelint-disable-next-line scss/dollar-variable-default
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  // 3.5px
  2:
    (
      $spacer * 0.5,
    ),
  // 7px;
  3r:
    (
      $spacer * 0.625,
    ),
  // 10px
  3:
    (
      $spacer * 0.75,
    ),
  // 10.5px
  4:
    (
      $spacer * 1,
    ),
  // 14px
  5:
    (
      $spacer * 1.25,
    ),
  // 17.5px
  6:
    (
      $spacer * 1.5,
    ),
  // 21px
  7:
    (
      $spacer * 1.75,
    ),
  // 24.5px
  7e:
    (
      $spacer * 1.875,
    ),
  // 30 on 16 base rem
  8:
    (
      $spacer * 2,
    ),
  // 28px
  9:
    (
      $spacer * 2.25,
    ),
  // 31.5px
  10:
    (
      $spacer * 2.5,
    ),
  // 35px
  11:
    (
      $spacer * 2.75,
    ),
  // 38.5px
  12:
    (
      $spacer * 3,
    ),
  // 42px
  13:
    (
      $spacer * 3.25,
    ),
  // 45.5px
  14:
    (
      $spacer * 3.5,
    ),
  // 49px
  15:
    (
      $spacer * 3.75,
    ),
  // 52.5px
  16:
    (
      $spacer * 4,
    ),
  // 55px
  17:
    (
      $spacer * 4.25,
    ),
  // 58.5px
  18:
    (
      $spacer * 4.5,
    ),
  // 62px
  19:
    (
      $spacer * 4.75,
    ),
  // 65.5px
  20:
    (
      $spacer * 5,
    ),
  25: (
    $spacer * 5.625,
  )
  // 69px ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

// Heading sizes
$h1-font-size: $font-size-base * 1.75 !default; // 22.75px
$h2-font-size: $font-size-base * 1.5 !default; // 19.50px
$h3-font-size: $font-size-base * 1.35 !default; // 17.55px
$h4-font-size: $font-size-base * 1.25 !default; // 24px
$h5-font-size: $font-size-base * 1.125 !default; // 18px
$h6-font-size: $font-size-base * 1.075 !default; // 13.97px

// Font Sizes
$font-sizes: (
  1: $h1-font-size,
  // 22.75px
  2: $h2-font-size,
  // 19.50px
  3: $h3-font-size,
  // 17.55px
  22s: $font-size-base * 1.375,
  //font sixe 22px
  4: $h4-font-size,
  // 16.25px
  5: $h5-font-size,
  // 14.95px
  6: $h6-font-size,
  // 13.95px
  7: $font-size-base * 0.95,
  // 12.35px
  8: $font-size-base * 0.875,
  // 11.05px
  9: $font-size-base * 0.75,
  // 9.75px
  10e: $font-size-base * 0.75,
  // regular 10px
  10: $font-size-base * 0.625,
  // 6.50px
  base: $font-size-base,
  // 13px
  fluid: 100%,
  // 100%
  2x: $font-size-base * 2,
  // 26px
  2qx: $font-size-base * 2.25,
  // 29.25px
  2hx: $font-size-base * 2.5,
  // 32.5px
  2tx: $font-size-base * 2.75,
  // 35.75px
  3x: $font-size-base * 3,
  // 39px
  3qx: $font-size-base * 3.25,
  // 42.25px
  3hx: $font-size-base * 3.5,
  // 45.5px
  3tx: $font-size-base * 3.75,
  // 48.75px
  4x: $font-size-base * 4,
  // 52px
  4qx: $font-size-base * 4.25,
  // 55.25px
  4hx: $font-size-base * 4.5,
  // 58.5px
  4tx: $font-size-base * 4.75,
  // 61.75px
  5x: $font-size-base * 5,
  // 65px
  5qx: $font-size-base * 5.25,
  // 68.25px
  5hx: $font-size-base * 5.5,
  // 71.5px
  5tx: $font-size-base * 5.75
    // 74.75px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
) !default;

$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: $font-weight-bolder !default;
$headings-line-height: 1.2 !default;
$headings-color: $gray-100 !default;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
) !default;

// Links
//
// Style anchor elements.
// $link-color: $primary !default;
// $link-decoration: none !default;
// $link-hover-color: darken($primary, 15%) !default;
// $link-hover-decoration: underline !default;
// // Darken percentage for links with `.text-*` class (e.g. `.text-success`)
// $emphasized-link-hover-darken-percentage: 15% !default;

$display-font-weight: $font-weight-bolder !default;
$display-line-height: $headings-line-height !default;

// Border settings
$border-color: $gray-200 !default;
$border-dashed-color: $gray-300 !default; // Custom variable
$border-width: 1px !default;

// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: 1.5rem !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-font-weight: $font-weight-bold !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: 1.25rem !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: 1.75rem !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-box-shadow: null !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: null !default;
$btn-disabled-opacity: 0.6 !default;
$btn-active-box-shadow: null !default;

// $btn-link-color: $link-color !default;
// $btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $gray-700 !default;

$form-check-input-width: 2.35rem !default;
$form-check-input-width-sm: 1.5rem !default;
$form-check-input-width-lg: 2.25rem !default;
$form-check-input-bg-size: 60% 60% !default; // Custom variable
$form-check-min-height: $font-size-base * $line-height-base !default;
$form-check-padding-start: $form-check-input-width + 0.5rem !default;
$form-check-padding-left: $form-check-input-width + 0.5rem !default;
$form-check-margin-bottom: 0.125rem !default;
$form-check-label-color: null !default;
$form-check-label-cursor: null !default;
$form-check-transition: background-color 0.15s ease-in-out,
  background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

$form-check-input-active-filter: brightness(90%) !default;

$form-check-input-indeterminate-color: $component-active-color !default;
$form-check-input-indeterminate-bg-color: $component-active-bg !default;
$form-check-input-indeterminate-border-color: $component-active-bg !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity: 0.5 !default;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity !default;

$form-switch-color: rgba(0, 0, 0, 0.25) !default;
$form-switch-color-solid: $gray-500 !default;
$form-switch-width: 3.25rem !default;
$form-switch-height: 2.25rem !default; // Custom variable
$form-switch-width-sm: 2.5rem !default; // Custom variable
$form-switch-height-sm: 1.5rem !default; // Custom variable
$form-switch-width-lg: 3.75rem !default; // Custom variable
$form-switch-height-lg: 2.75rem !default; // Custom variable

$form-switch-padding-start: $form-switch-width + 0.5rem !default;
$form-switch-padding-left: $form-switch-width + 0.5rem !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-bg-image-solid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-solid}'/></svg>") !default;
$form-switch-border-radius: $form-switch-width !default;

$form-switch-focus-color: $input-focus-border-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color: $component-active-color !default;
$form-switch-unchecked-color: $gray-800 !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-unchecked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-unchecked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;
$primary-text-emphasis-dark: #000;
$secondary-text-emphasis-dark: #fff;
$success-text-emphasis-dark: #fff;
$warning-text-emphasis-dark: #fff;
$danger-text-emphasis-dark: #fff;
$info-text-emphasis-dark: #fff;
$light-text-emphasis-dark: #fff;
$dark-text-emphasis-dark: #fff;

$secondary-bg-subtle-dark: #fff;
$success-bg-subtle-dark: #fff;
$info-bg-subtle-dark: #fff;
$warning-bg-subtle-dark: #fff;
$danger-bg-subtle-dark: #fff;
$light-bg-subtle-dark: #fff;
$dark-bg-subtle-dark: #fff;
$primary-bg-subtle-dark: #fff;

$primary-border-subtle-dark: #fff;
$secondary-border-subtle-dark: #fff;
$success-border-subtle-dark: #fff;
$info-border-subtle-dark: #fff;
$warning-border-subtle-dark: #fff;
$danger-border-subtle-dark: #fff;
$light-border-subtle-dark: #fff;
$dark-border-subtle-dark: #fff;

$body-color-dark: #fff;
$body-color-dark: #fff;
$body-bg-dark: #ffff;
$body-bg-dark: #fff;
$body-emphasis-color-dark: #fff;
$body-secondary-color-dark: #fff;
$body-tertiary-color-dark: #fff;
