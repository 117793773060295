
.homePage {
    padding-block: 40px 50px;
    background: var(--neutral-white-100, #FDFDFF);
    font-family: Inter, sans-serif;
    .section-center{
  width: 90vw;
  margin: 0 auto;
  max-width: 1390px;

  
}

@media screen and (min-width: 992px){
  .section-center{
    width: 95vw;
  }
}

    nav {


     

        display: grid;
                padding-inline: 130px;
                // width: 100vw;

        @media (max-width: 968px) {
             padding-inline: 0px;
        }
        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
            place-self: center;
            width: 100%;
            max-width: 1440px;

            .navColumn2 {
                display: flex;
                gap: 30px;

                .navColumn2Value {
                    display: flex;
                    align-items: center;
                    color: var(--text-600, #050542);
                    font-family: Inter, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
            }

            .authDiv {
                display: flex;
                gap: 20px;
                @media (max-width: 968px) {
                    display: none;
                }

                .signInButton {
                    color: var(--neutral-white-50, #FFF);
                    font-family: Inter, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    border-radius: 40px;
                    background: var(--primary-purple-800, #0866FF);
                    border: none;
                    display: flex;
                    align-items: center;
                    padding: 8px 16px;
                }

                .createButton {
                    color: var(--primary-purple-800, #0866FF);
                    font-family: Inter, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    border-radius: 40px;
                    background: var(--primary-purple-50, #E6EFFF);
                    border: none;
                    display: flex;
                    align-items: center;
                    padding: 8px 16px;
                }
            }
        }
    }

    .homePageRow1 {
        display: grid;
        place-content: center;
        > div {
            max-width: 1440px;
            padding-block: 110px;
            padding-inline: 215px;

              @media (max-width: 968px) {
            padding-inline: 0;
              }

            .homePageRow1Text {
                padding-bottom: 57px;
                text-align: center;
                color: var(--text-600, #050542);
                font-family: Inter, sans-serif;
                font-size: 64px;
                font-style: normal;
                font-weight: 700;
                line-height: 72px;
                //padding-inline: 215px;
                max-width: 1000px;

                span {
                    color: var(--primary-purple-400, #B877FF);
                }
            }

            .getStartedButton {
                text-align: center;

                button {
                    color: var(--primary-purple-900, #0866FF);
                    background-color: white;
                    text-align: center;
                    font-family: Inter, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    border-radius: 50px;
                    border: 2px solid var(--primary-purple-900, #0866FF);
                    padding: 5px 45px;
                }
            }
        }
    }

    .homePageRow2 {
       text-align: center;
        border-radius: 100px 100px 0 0;
        background: var(--primary-purple-700, #2075FF);
        margin-top: 100px;

        img {
            margin-top: -200px;
        }

        .img-withd{
            width: 720;
        }

        @media (max-width: 968px) {
           img:not(.nav-logo, .avart){
width: 100%;
display: block;
}
 .img-withd{
            width: 0;
        }
       
        }

            @media (max-width: 968px) {

            .img-docs{
width: 100%;
display: block;
}

             }

            .img-docs{
                width: 642;
            }




        .weAreText {
            color: var(--neutral-white-50, #FFF);
            font-family: Inter, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            padding-block: 20px 28px;
              margin-top: 20px;
        }

        .logosdiv {
            display: flex;
            gap: 40px;
            align-items: center;
            justify-content: center;
            padding-bottom: 27px;
            margin-top: 20px;
               @media (max-width: 968px) {
                display: none;
               }
        }
    }

    .homePageRow3 {
        padding: 100px 100px 100px 130px;
        display: grid;
        place-content: center;
        > div {
            display: flex;
            max-width: 1440px;

            @media (max-width: 968px) {
                flex-direction: column;

            }

                   @media (max-width: 968px) {

            .img-docs{
width: 100%;
display: block;
}

             }

            .img-docs{
                width: 642;
            }

            .leftText {
                font-family: Inter, sans-serif;

                h3 {
                    color: var(--text-500, #050649);
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 56px; /* 116.667% */
                }

                span {
                    color: var(--neutral-white-900, #69696B);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px; /* 177.778% */
                }

                button {
                    margin-block: 100px;
                    display: flex;
                    width: 267px;
                    padding: 10px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 50px;
                    border: 2px solid var(--primary-purple-900, #0866FF);
                    background-color: white;
                }
            }
        }
    }

    .homePageRow4 {
        clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 100%);
        background-color: var(--primary-purple-700, #2075FF);
        padding-inline: 130px;
        padding-block: 200px 50px;
        display: grid;
        place-content: center;

        > div {
            display: flex;
            gap: 67px;
            max-width: 1440px;

            @media (max-width: 968px) {
                img{
                    display: none;
                }

            }

            .rightText {
                h3 {
                    color: var(--text-50, #E6E6ED);
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 56px; /* 116.667% */
                }

                span {
                    color: var(--text-50, #E6E6ED);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px; /* 177.778% */
                }

                button {
                    display: flex;
                    width: 267px;
                    padding: 10px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 50px;
                    border: 2px solid var(--primary-purple-50, #E6EFFF);
                    margin-block: 100px;
                    background-color: transparent;
                    color: var(--primary-purple-50, #E6EFFF);
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
    }

    .homePageRow5 {
        display: grid;
        place-content: center;
        padding-inline: 130px;
        padding-block: 200px 100px;

        > div {
            text-align: center;
            max-width: 1440px;
            display: grid;
            place-content: center;

            h3 {
                color: var(--text-500, #050649);
                text-align: center;
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                line-height: 48px;
                max-width: 780px;
            }

            div {
                color: var(--neutral-white-900, #69696B);
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;
                max-width: 780px;
                display: block;
            }

            .companyLogos {
                display: flex;
                gap: 16px;
                margin-top: 40px;
                flex-wrap: wrap;
                justify-content: center;
                max-width: 780px;

                img {
                    width: 100px;
                    height: 100px;
                }
            }

            .ctaButton {
                margin-block: 80px;
                display: flex;
                justify-content: center;
                gap: 20px;
                .registerAsBiller {
                    border: none;
                    color: var(--neutral-white-50, #FFF);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    display: flex;
                    padding: 8px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 40px;
                    background: var(--primary-purple-800, #0866FF);
                }

                .payRegisteredBiller {
                    color: var(--primary-purple-800, #0866FF);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    display: flex;
                    padding: 8px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 40px;
                    background: var(--primary-purple-50, #E6EFFF);
                }
            }
        }
    }

    .homePageRow6 {
        display: grid;
        place-content: center;
        padding: 100px 130px;
        > div {
            max-width: 1440px;
            h3 {
                color: var(--text-500, #050649);
                font-size: 56px;
                font-style: normal;
                font-weight: 600;
                line-height: 64px;
                max-width: 780px;
                margin-block: 10px;
            }

            > span {
                color: var(--neutral-white-900, #69696B);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;
                max-width: 780px;
            }

            .cardRow {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-top: 40px;

                > div {
                    display: flex;
                    height: 324px;
                    padding: 20px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    flex: 1 0 0;
                    color: var(--text-900, #02031F);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 161.9%;

                    strong {
                        display: block;
                        color: var(--text-500, #050649);
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px;
                    }

                    a {
                        text-decoration: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        color: var(--secondary-blue-600, #369EE7);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .homePageRow7 {
        display: grid;
        place-content: center;
        padding: 100px 130px;

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1440px;
            gap: 40px;

             @media (max-width: 968px) {
                flex-direction: column;

                 .phone {
                // margin-left: -80px;
                width: 100%;
                display: block;
            }


            }

            

            .phone {
                margin-left: -80px;
                width: 700;
            }


            .leftSide {
                display: grid;
                gap: 30px;
                strong {
                    display: block;
                    color: var(--primary-purple-900, #0866FF);
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 40px;
                    margin-bottom: 10px;
                }

                span {
                    color: var(--neutral-white-900, #69696B);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                }

                .buttonRow {
                    display: flex;
                    align-items: flex-start;
                    gap: 21px;
                    button {
                        border: none;
                        display: flex;
                        width: 166px;
                        height: 61px;
                        padding: 10px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 50px;
                        background: linear-gradient(128deg, #6366F1 28.07%, #3BAEFE 116.76%);
                        color: var(--text-50, #E6E6ED);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 32px;
                        span {
                            color: inherit;
                        }
                    }
                }
            }
        }
    }

    .homePageRow8 {
        // padding: 100px 328px 0 328px;

        > div {
            max-width: 1440px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;

             @media (max-width: 968px) {

            .img-docs{
width: 100%;
display: block;
}

             }

            .img-docs{
                width: 642;
            }

            h3 {
                color: var(--text-500, #050649);
                text-align: center;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 40px;
                margin-block: 5px;
            }


           .integrateTo {
            color: var(--neutral-white-900, #69696B);
            text-align: center;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            }

            .homePageRow8Button {
                margin-block: 40px;
                display: flex;
                justify-content: center;
                gap: 20px;

                .simplyPayButton {
                    border: none;
                    color: var(--neutral-white-50, #FFF);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    display: flex;
                    padding: 8px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 40px;
                    background: var(--primary-purple-800, #0866FF);
                }

                .readDocumentButton {
                    color: var(--primary-purple-800, #0866FF);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    display: flex;
                    padding: 8px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 40px;
                    background: var(--primary-purple-50, #E6EFFF);
                    border: none;
                }
        
            }
        }
    }

    .homePageRow9 {
        display: grid;
        place-content: center;
        padding: 80px 130px;
        background: var(--primary-purple-900, #0866FF);

        > div {
            text-align: center;
            max-width: 1440px;
            display: grid;
            place-content: center;

            .startAutomating {
                color: var(--neutral-white-50, #FFF);
                text-align: center;
                font-family: Inter;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 40px;
            }

            .getInsight {
                color: var(--neutral-white-500, #F0F0FF);
                text-align: center;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;
            }

            .homePageRow9Button {
                margin-block: 40px;
                display: flex;
                justify-content: center;
                gap: 20px;

                .createAcctButton {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    display: flex;
                    padding: 8px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 40px;
                    background: var(--primary-purple-50, #E6EFFF);
                    border: none;
                }

                .requestDemoButton {
                    color: var(--text-50, #E6E6ED);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    border-radius: 40px;
                    border: 1px solid var(--text-50, #E6E6ED);
                    background: var(--primary-purple-900, #0866FF);
                    display: flex;
                    padding: 8px 20px;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .homePageFooter {
        display: grid;
        padding-top: 80px;
        padding-inline: 130px;

        > div {
            max-width: 1440px;

            .footerRow {
                display: flex;
                padding-bottom: 40px;
                gap: 20px;

                > :first-child {
                    flex-basis: 20%;
                }

                > :last-child {
                    flex-basis: 80%;
                    gap: 20px;
                    display: grid;
                    grid-template-columns: 18% 18% 18% 18% 18%;

                    .footerRowValue {
                }
                    strong {
                        color: var(--text-500, #050649);
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                    }

                    span {
                        display: block;
                        color: var(--text-900, #02031F);
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }

            .lineBreak {
                padding-bottom: 40px;
            }

            .copyright {
                display: flex;
                gap: 20px;

                > :first-child {
                    flex-basis: 20%;
                }

                span {
                    color: var(--text-500, #050649);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
                
            }
        }
    }

   
}
