.reprocessUnpaidPayments {
  padding: 30px;

  .rupBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  .rupRadioButtons {
    background: #FFFFFF;
    width: 543px;
    display: grid;
    margin: auto;
    padding: 40px;

    .tpText {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #061B01;
      padding-bottom: 40px;
    }

    .typeOfTransaction {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #08140C;
      padding-bottom: 40px;
    }

    .radioLabel {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      padding-bottom: 20px;

      input[type="radio"]:checked {
        border-color: green;
      }
    }

    .inputLabel {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
    }

    .tpProceedButton {
      padding-top: 30px;
      text-align: center;

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #FFFFFF;
        border-radius: 4px;
        padding: 12px 34px;
        border: none;
      }
    }
  }
}

.rejectedPayments {
  padding: 35px;
  overflow-y: auto;

  .rejectedPaymentsBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #AEAEAE;
      padding-block: 23px 60px;

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #061B01;
      }
  }

    .rejectedPaymentsSummary {
      padding-inline: 47px;

      .rpsRow1 {
        border: 0.5px solid #E8E8E8;
        border-radius: 4px;
        padding-inline: 50px;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: #08140C;
          padding-block: 18px 16px;
          border-bottom: 1px solid #E8E8E8;
        }

        > :nth-child(2) {
          display: grid;
          justify-content: right;
          padding-block: 16px;
          border-bottom: 1px solid #E8E8E8;

          > :first-child {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #0866FF;
            padding-bottom: 7px;
          }

          > :nth-child(2) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
          }
        }

        > :last-child {
          display: flex;
          justify-content: space-between;
          padding-block: 15px;

            


          .psTitle {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
            padding-bottom: 4px;
          }

          .psValue {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #061B01;
          }
        }
      }

      .rpsRow2 {
        display: flex;
        justify-content: space-between;
        padding-block: 40px 30px;

        > :first-child {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        .addFilterButton {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #666666;
          background: #f6eeff;
          border-radius: 4px;
          padding: 10px;
          border: none;
          display: flex;
          gap: 13px;
          align-items: center;
        }

        .resetButton {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          border: 1px solid #CCCCCC;
          border-radius: 5px;
          padding: 10px;
          background: #deebf4;
        }

        .submitCancelButton {
          display: flex;
          gap: 15px;

          > :first-child {
            button {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-align: center;
              color: #0866FF;
              background: #FFFFFF;
              border-radius: 4px;
              padding: 12px 40px;
              border: 1px solid #0866FF;

              //&:disabled {
              //  cursor: not-allowed;
              //  background: #898989;
              //}
            }
          }

          > :last-child {
            button {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-align: center;
              color: #FFFFFF;
              background: #0866FF;
              border-radius: 4px;
              padding: 12px 40px;
              border: none;

              //&:disabled {
              //  cursor: not-allowed;
              //  background: #898989;
              //}
            }
          }
        }
      }

      .psRow3 {
        .ant-table-content {
          width: 100%;
          overflow-x: auto;
        }

        th.ant-table-cell {
          background: #f6eeff;
        }

        //input.ant-checkbox-input {
        //  background: #0866FF;
        //}
      }
    }
 }


 .rpsRow2-form{
  display: flex;
  justify-content: center;
  align-items: center;
          padding-block: 40px 30px;

 }

 .form-control-reject{
  display: flex;
width: 400px;
height: 44px;
padding: 0px 10px;
justify-content: center;
align-items: center;
gap: 171px;
border-radius: 5px;
border: 1px solid #CCC;
background: #F2F2F2;
 }