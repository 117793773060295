.cashForecast {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .cashForecastBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 30px 12px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: normal;
      color: #666666;
    }

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: normal;
      color: #08140C;
    }
  }

  .cashForecastRow2 {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 12px;
    padding-top: 28px;

    > :first-child {
      border-radius: 5px;
      background: #FFFFFF;
      padding-inline: 17px;

      > :last-child {

        button {
          color: rgba(255, 255, 255, 0.80);
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 4px;
          background: #0866FF;
          width: 100%;
          border: none;
        }
      }
    }

    > :last-child {
     .pieChartDiv {
        border-bottom: 1px solid #E8E8E8;
        margin-bottom: 39px;
        padding-block: 39px;
        display: flex;
        gap: 27px;
        align-items: center;
        padding-left: 17px;

        > :first-child {
          width: 120px;
          height: 120px;
        }

        > :nth-child(2) {
          display: flex;

          > :first-child {
            padding-right: 27px;
            border-right: 1px solid #E8E8E8;

            > :first-child {
              color: #666666;
              font-family: Poppins, sans-serif;
              font-size: 48px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -1.8px;
            }

            > :last-child {
              color: #828282;
              font-family: Poppins, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          > :nth-child(2) {
            padding-left: 17px;

            > :first-child {
              color: #666666;
              font-family: Poppins, sans-serif;
              font-size: 48px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -1.8px;
            }

            > :last-child {
              color: #828282;
              font-family: Poppins, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }

      .lineChartDiv {
        border-radius: 5px;
        background: #FFFFFF;
        box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.04);

        > :first-child {
          color: #000;
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-block: 15px 25px;
          padding-left: 70px;
        }

        > :nth-child(2) {
          padding-inline: 20px;
        }
      }
    }
  }
}
