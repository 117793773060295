.loginPage {
    font-family: Poppins, sans-serif;
    display: flex;
    min-height: 100vh;
    
    .loginPageLeftDiv {
        flex-basis: 30%;
        background: var(--primary-purple-50, #E6EFFF);
        padding-inline: 83px 50px;
        padding-top: 55px;

       > div {
        > :nth-child(2) {
            color: var(--primary-purple-900, #0866FF);
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            padding-block: 157px 62px;
            max-width: 353px;
        }

        .loginImgDiv {
           img {
            // margin-right: -400px;
           }
        }
       }
    }

    .loginPageRightDiv {
        flex-basis: 70%;
        background: #FFF;
        padding-top: 55px;
        display: grid;

        .simplypayHome {
            display: flex;
            gap: 2px;
            justify-content: right;
            padding-right: 93px;
        
            
            span {
                color: var(--secondary-blue-500, #3BAEFE);
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                cursor: poPoppins;
            }
        }

        .loginForm {
            max-width: 400px;
            margin: auto;
            padding-block: 100px;

            .forgotPassword {
                color: var(--secondary-blue-500, #3BAEFE);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        
            
            .submitButton {
                    display: flex;
                    width: 400px;
                    height: 48px;
                    padding: 10px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 4px;
                    background: var(--primary-purple-700, #2075FF);
                }

            .createAcctDiv {
                color: var(--secondary-blue-500, #050542);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                span {
                color: var(--secondary-blue-500, #3BAEFE);
                cursor: poPoppins;
                }
            }
            
        }

        
    }

}

.ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
}


@media (max-width: 968px) {
    .loginPageLeftDiv {
        display: none;
    }

    .loginPage{
            display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
    height: 100vh;
    width: 100%;
    background: #F5F5F5;
    }
}