.payrollPayments {
  padding: 40px 30px;
  overflow-y: auto;

  .payrollPaymentsBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  .payrollPaymentsRadioButtons {
    background: #FFFFFF;
    width: 480px;
    display: grid;
    margin: auto;
    padding: 40px;

    .ppText {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #061B01;
      padding-bottom: 40px;
    }

    .downloadTemplate {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #999999;
      padding-bottom: 10px;
      gap: 8px;
    }

    label {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      padding-bottom: 20px;

      input[type="radio"]:checked {
        border-color: green;
      }
    }

    .ppProceedButton {
      padding-top: 30px;
      text-align: center;

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #FFFFFF;
        border-radius: 4px;
        padding: 12px 34px;
        border: none;
      }
    }
  }
}

.salaryOnly {
  padding: 30px;
  overflow-y: auto;

  .salaryOnlyBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  .payrollPaymentForm {
    margin: auto;
    background: #FFFFFF;
    padding: 40px;
    width: 480px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #061B01;
      padding-bottom: 40px;
    }

    > :nth-child(2) {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
    }

    > :nth-child(3) {
      display: flex;
      gap: 20px;
      justify-content: space-between;

      >div {
        width: 190px;

        select {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #08140C;
        }
      }
    }

    .ppFileUploadDiv {
      padding-block: 20px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }

      label {
        display: flex;
        gap: 20px;
        align-items: center;

        > :first-child {
          background: #deebf4;
          border-radius: 4px;

          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #3BAEFE;
          padding: 12px 34px;
          border: none;
          display: inline-block;
          cursor: pointer;
        }

        > :nth-child(2) {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #666666;
          word-wrap: break-word;
          width: 240px;
        }
      }

      .fileTypeText {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.280702px;
        color: #AAAAAA;
        padding-top: 5px;
      }
    }

    .ppDescriptions {
      padding-bottom: 10px;

      label {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }

    > :last-child {
      display: flex;
      gap: 30px;
      justify-content: center;
      padding-top: 20px;

      .soCancelButton {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: #0866FF;
          background: #FFFFFF;
          padding: 11px 34px;
          border: 1px solid #0866FF;
          border-radius: 4px;
        }
      }

      .soPreviewButton {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: #FFFFFF;
          padding: 12px 34px;
          background: #0866FF;
          border-radius: 4px;
          border: none;
        }
      }
    }
  }

  .ppBreakdown {

    .ppBreakdownRow1 {
      background: #FFFFFF;
      border-radius: 4px;
      margin-block: 30px 12px;
      padding-block: 20px 16px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #08140C;
        text-align: center;
        border-bottom: 1px solid #E8E8E8;
        padding-bottom: 10px;
      }

      > :nth-child(2) {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #E8E8E8;
        padding-inline: 82px;
        padding-block: 10px;

        > :first-child {
          display: flex;
          gap: 51px;

          div {
            .batchNoDate {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
              color: #666666;
            }

            .batchNoDateValue {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: #061B01;
            }
          }
        }

        > :last-child {
          .gtpAmt {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #0866FF;
            padding-bottom: 7px;
          }

          .gtp {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 4px;
            color: #666666;
          }
        }
      }

      > :nth-child(3) {
        display: flex;
        justify-content: space-between;
        padding-inline: 82px;

        > :nth-child(2) {
          display: flex;
          gap: 23px;
        }

        .ppTotalTitle {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          padding-block: 10px 4px;
        }

        .ppTotalAmt {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #061B01;
        }
      }
    }

    .ppBreakdownRow2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;

      > :first-child {
        display: flex;
        gap: 20px;

        > :first-child {
          button {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
            background: #f6eeff;
            border-radius: 4px;
            padding: 12px 20px;
            border: none;
            gap: 10px;
            display: flex;
            align-items: center;
          }
        }

        > :last-child {
          button {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
            background: #deebf4;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            padding: 11px 15px;
          }
        }
      }

      > :last-child {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #FFFFFF;
          background: #0866FF;
          border-radius: 4px;
          padding: 12px 43px;
          border: none;
        }
      }
    }

    .ppBreakdownRow3 {
      th.ant-table-cell {
        background: #F2F2F2;
      }
    }


  }
}

.salaryAll {
  padding: 30px;
  overflow-y: auto;

  .salaryAllBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  .payrollPaymentForm {
    margin: auto;
    background: #FFFFFF;
    padding: 40px;
    width: 480px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #061B01;
      padding-bottom: 40px;
    }

    > :nth-child(2) {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
    }

    > :nth-child(3) {
      display: flex;
      gap: 20px;
      justify-content: space-between;

      >div {
        width: 190px;

        select {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #08140C;
        }
      }
    }

    .ppFileUploadDiv {
      padding-block: 20px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }

      label {
        display: flex;
        gap: 20px;
        align-items: center;

        > :first-child {
          background: #deebf4;
          border-radius: 4px;

          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #3BAEFE;
          padding: 12px 34px;
          border: none;
          display: inline-block;
          cursor: pointer;
        }

        > :nth-child(2) {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #666666;
          word-wrap: break-word;
          width: 240px;
        }
      }

      .fileTypeText {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.280702px;
        color: #AAAAAA;
        padding-top: 5px;
      }
    }

    .ppDescriptions {
      padding-bottom: 10px;

      label {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }

    > :last-child {
      display: flex;
      gap: 30px;
      justify-content: center;
      padding-top: 20px;

      .soCancelButton {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: #0866FF;
          background: #FFFFFF;
          padding: 11px 34px;
          border: 1px solid #0866FF;
          border-radius: 4px;
        }
      }

      .soPreviewButton {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: #FFFFFF;
          padding: 12px 34px;
          background: #0866FF;
          border-radius: 4px;
          border: none;
        }
      }
    }
  }

  .ppBreakdown {

    .ppBreakdownRow1 {
      background: #FFFFFF;
      border-radius: 4px;
      margin-block: 30px 12px;
      padding-block: 20px 16px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #08140C;
        text-align: center;
        border-bottom: 1px solid #E8E8E8;
        padding-bottom: 10px;
      }

      > :nth-child(2) {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #E8E8E8;
        padding-inline: 82px;
        padding-block: 10px;

        > :first-child {
          display: flex;
          gap: 51px;

          div {
            .batchNoDate {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
              color: #666666;
            }

            .batchNoDateValue {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: #061B01;
            }
          }
        }

        > :last-child {
          .gtpAmt {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #0866FF;
            padding-bottom: 7px;
          }

          .gtp {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 4px;
            color: #666666;
          }
        }
      }

      > :nth-child(3) {
        display: flex;
        justify-content: space-between;
        padding-inline: 82px;

        > :nth-child(2) {
          display: flex;
          gap: 23px;
        }

        .ppTotalTitle {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          padding-block: 10px 4px;
        }

        .ppTotalAmt {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #061B01;
        }
      }
    }

    .ppBreakdownRow2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;

      > :first-child {
        display: flex;
        gap: 20px;

        > :first-child {
          button {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
            background: #f6eeff;
            border-radius: 4px;
            padding: 12px 20px;
            border: none;
            gap: 10px;
            display: flex;
            align-items: center;
          }
        }

        > :last-child {
          button {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
            background: #deebf4;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            padding: 11px 15px;
          }
        }
      }

      > :last-child {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #FFFFFF;
          background: #0866FF;
          border-radius: 4px;
          padding: 12px 43px;
          border: none;
        }
      }
    }

    .ppBreakdownRow3 {
      th.ant-table-cell {
        background: #F2F2F2;
      }
    }


  }
}
